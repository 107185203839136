import React, { useEffect, useMemo, useState } from "react";
// page animation
import WOW from "wowjs";
import "../../../../assets/css/animate.css";
import GallaryIem1 from "../../../../assets/images/gallary/e-gallary1.png";
import { SRLWrapper } from "simple-react-lightbox";
// main sponser logo image import
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import ModalChooseTicket from "../../../common/Modals/ModalChooseTicket";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ModalBookingOverview from "../../../common/Modals/ModalBookingOverview";
import { isAuthenticated } from "../../../../utils/auth-check";
import { fetchEventReviewById } from "../../../../services/events";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";

function renderEventContent(eventInfo) {
  const isNotAvailable = moment().isAfter(eventInfo.event.start);
  return (
    <div
      className={
        eventInfo.view.type !== "listWeek"
          ? "text-center w-100 text-white py-1"
          : ""
      }
      style={
        eventInfo.view.type !== "listWeek"
          ? {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              ...(isNotAvailable ? { opacity: "0.5" } : {}),
            }
          : {}
      }
    >
      <strong>{moment(eventInfo.event?.start).format("HH:mm a")}</strong>
      <div className="my-1">to</div>
      <strong>{moment(eventInfo.event?.end).format("HH:mm a")}</strong>
      <div className="mt-1">
        <i>{eventInfo.event.title}</i>
      </div>
    </div>
  );
}

const EventDetailsLeftArea = (props) => {
  const { data: eventDetails } = props;
  const [reviewData, setReviewData] = useState([]);
  const [reviewPage, setReviewPage] = useState(1);

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  useEffect(() => {
    getReview();
  }, [eventDetails.id]);

  const paginatedReviewData = useMemo(() => {
    return reviewData.slice((reviewPage - 1) * 5, reviewPage * 5);
  }, [reviewData, reviewPage]);

  const handlePrevReviewPage = () => {
    if (reviewPage > 1) {
      setReviewPage((prev) => prev - 1);
    }
  };

  const handleNextReviewPage = () => {
    if (reviewPage < Math.ceil(reviewData.length / 5)) {
      setReviewPage((prev) => prev + 1);
    }
  };

  const getReview = async () => {
    try {
      if (eventDetails.id === undefined) return;
      const response = await fetchEventReviewById({
        event_id: eventDetails.id,
      });
      if (response.status === 200 || response.status === 201) {
        setReviewData(response.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const events = useMemo(() => {
    if (!eventDetails) return [];
    return (
      eventDetails.timeslots?.map((timeslot) => {
        return {
          title: eventDetails.name,
          start: timeslot.start_date,
          end: timeslot.end_date,
          id: timeslot.id,
        };
      }) || []
    );
  }, [eventDetails]);
  if (!eventDetails) return null;

  return (
    <>
      <div className="col-xl-8">
        <div className="ed-main-wrap">
          <div className="ed-tabs-wrapper">
            <div>
              <div className="details-tab-content">
                <h3 className="ed-title">Description</h3>
                <div
                  className="event-description"
                  dangerouslySetInnerHTML={{ __html: eventDetails.description }}
                ></div>
                <div className="row ed-ticket-info mt-5">
                  <div className="col-lg-6">
                    <h5 className="ed-subtitle">Ticket Information</h5>
                  </div>
                </div>
                <div
                  className="ed-ticket-accordion accordion mt-4"
                  id="TicketInfo"
                >
                  {eventDetails.tickets?.map((ticket, idx) => (
                    <div key={`ticket-${ticket.id}`} className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`tiHeading${ticket.id}`}
                      >
                        <button
                          className={`accordion-button ${
                            idx === 0 ? "" : "collapsed"
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#tiCollapse${ticket.id}`}
                          aria-expanded={idx === 0 ? "true" : "false"}
                          aria-controls={`#tiCollapse${ticket.id}`}
                        >
                          <div>{ticket.name}</div>
                          <div className="ticket-price ms-auto">
                            <div className="me-4">
                              ${Number(ticket.price).toFixed(2)}
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`tiCollapse${ticket.id}`}
                        className={`accordion-collapse collapse ${
                          idx === 0 ? "show" : ""
                        }`}
                        aria-labelledby={`tiHeading${ticket.id}`}
                        data-bs-parent="#TicketInfo"
                      >
                        <div className="accordion-body">
                          <h5>Description</h5>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: ticket.description,
                            }}
                          ></div>
                          <div className="mt-3">
                            Total Tickets : {ticket.total_ticket}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="ed-calendar mt-5">
                  <h5 className="ed-subtitle">Event Calendar</h5>
                </div>
                <div id="event-date" className="ed-calendar-content mt-4">
                  <FullCalendar
                    plugins={[dayGridPlugin, listPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                    }}
                    weekends={true}
                    events={events}
                    eventContent={renderEventContent}
                    eventColor="#702482"
                    height={"auto"}
                    eventConstraint={{
                      start: moment().toISOString(),
                      end: moment("2100-12-31").toISOString(),
                    }}
                    // eventClick={(info) => {
                    //   info.jsEvent.preventDefault();
                    //   const isNotAvailable = moment().isAfter(info.event.start);
                    //   const invalidTimeEvent = !(
                    //     info.event.end && info.event.start
                    //   );
                    //   if (invalidTimeEvent)
                    //     return toast.error(
                    //       "Event time is invalid, please contact admin for more information"
                    //     );
                    //   if (isNotAvailable)
                    //     return toast.error("Event is cannot be booked");
                    //   if (!isLoggedIn) return checkLoggedIn();
                    //   setSelectedTimeslot(info.event.id);
                    //   setOpenModalChoseTicket(true);
                    // }}
                  />
                </div>
                <div className="ed-gallary mt-5">
                  <h5 className="ed-subtitle">Gallery</h5>
                </div>
                <div className="gallary-tab-content mt-3">
                  {eventDetails.medias?.length > 0 ? (
                    <SRLWrapper>
                      <div className="row">
                        {eventDetails.medias?.map(
                          (media, index) =>
                            media.status === 1 && (
                              <div key={`media-${index}`} className="col-lg-6">
                                <div className="gallary-item">
                                  <a href={media.url || GallaryIem1}>
                                    <img
                                      src={media.url || GallaryIem1}
                                      height="200"
                                      style={{ objectFit: "cover" }}
                                      alt="GallaryIMAGE"
                                    />
                                  </a>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </SRLWrapper>
                  ) : (
                    <div className="text-center display-6 text-secondary my-4">
                      No images
                    </div>
                  )}
                </div>
                {reviewData.length > 0 && (
                  <div className="review-section mt-5">
                    <h5 className="ed-subtitle mb-2">Reviews</h5>
                    <div className="comment-section">
                      <div className="comment-section-header">
                        <div className=" comment-arrow prev-comment ">
                          <a
                            role="button"
                            className={reviewPage === 1 ? "disabled" : ""}
                            onClick={handlePrevReviewPage}
                          >
                            <i className="bi bi-chevron-left" /> Previous
                          </a>
                        </div>
                        <div className="comment-counter">
                          <h5>
                            Reviews <span>({reviewData.length})</span>{" "}
                          </h5>
                        </div>
                        <div className=" comment-arrow next-comment ">
                          <a
                            role="button"
                            className={
                              reviewPage < Math.ceil(reviewData.length / 5)
                                ? ""
                                : "disabled"
                            }
                            onClick={handleNextReviewPage}
                          >
                            Next <i className="bi bi-chevron-right" />
                          </a>
                        </div>
                      </div>
                      <ul className="comments-list">
                        {paginatedReviewData.map((item) => (
                          <li
                            key={`user-review-${item.id}`}
                            className="single-comment"
                          >
                            <div className="card-body">
                              <h5>{item?.member?.name}</h5>
                              <div className="mt-1">
                                <Rating
                                  size={18}
                                  initialValue={item?.rating}
                                  readonly
                                />
                              </div>
                              <div className="mt-3">
                                <em>{item?.description}</em>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(EventDetailsLeftArea);
