import moment from "moment";
import Modal from "./Modal";
const ModalSelectTimeslot = ({
  isOpen = false,
  onClose,
  data,
  title,
  onSelect,
}) => {
  const eventIsStarted = (event) => {
    return moment().isAfter(event.start_date);
  };
  const sortedEvent = data?.sort((a, b) => {
    return moment(a.start_date).isAfter(moment(b.start_date)) ? 1 : -1;
  });
  return (
    <Modal title="Listed Event Time" isOpen={isOpen} onClose={onClose}>
      <div className="select-timeslot-container font-barlow">
        {data?.length ? (
          <div className="d-flex flex-column gap-3">
            {sortedEvent.map((item, index) => (
              <>
                <div
                  key={index}
                  className={`w-100 select-timeslot-card card ${
                    eventIsStarted(item) ? "expired" : ""
                  }`}
                  onClick={() => onSelect(item)}
                >
                  <div className="card-body w-100">
                    <h6 className="fw-bold mb-2">{title}</h6>
                    <div className="d-flex gap-2 justify-content-between align-items-center">
                      <div className="text-start fs6">
                        <p>
                          {moment(item.start_date).format("dddd, DD MMM YYYY")}
                        </p>
                        <h6 className="fw-bold select-timeslot-time">
                          {moment(item.start_date).format("HH:mm A")}
                        </h6>
                      </div>
                      <p>to</p>
                      <div className="text-end">
                        <p>
                          {moment(item.end_date).format("dddd, DD MMM YYYY")}
                        </p>
                        <h6 className="fw-bold select-timeslot-time">
                          {moment(item.end_date).format("HH:mm A")}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        ) : (
          <div className="text-center">No ticket available</div>
        )}
      </div>
      <div className="w-100 mt-5">
        <button
          className="primary-btn-outline w-100 btn btn-sm"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ModalSelectTimeslot;
