import axios from "axios";
import api from "../utils/api";

export const registerNewMember = async (payload) => {
  const URL = api.register;
  return axios.post(URL, payload);
};

export const loginMember = async (payload) => {
  const URL = api.login;
  return axios.post(URL, payload);
};

export const resetPasswordMember = async (payload) => {
  const URL = api.resetPassword;
  return axios.post(URL, payload);
};
