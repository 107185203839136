import { useEffect, useState } from "react";
import { loginMember } from "../../../services/auth";
import Modal from "./Modal";
import moment from "moment";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
const ModalLogin = ({ isOpen = false, onClose, events, ...props }) => {
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    login(data);
  };
  const login = async (data) => {
    try {
      setLoading(true);
      const response = await loginMember({ ...data, grant_type: "email" });
      if (response.status === 200) {
        const userData = {
          ...response.data.data,
        };
        localStorage.setItem(
          "expires_at",
          moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss")
        );
        delete userData.password;
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("token", response.data.data.access_token);
        // remove query login from url
        const pathname = props.history.location.pathname;
        const searchParams = new URLSearchParams(props.history.location.search);
        searchParams.delete("login");
        props.history.push(`${pathname}?${searchParams.toString()}`);
        props.history.go();
      }
    } catch (error) {
      console.error(error);
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const removeError = () => {
    setError(null);
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Modal
      title="Sign in to continue booking"
      hideClose
      isOpen={isOpen}
      onClose={onClose}
    >
      <form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
        {error && (
          <div className="alert alert-danger text-danger small">{error}</div>
        )}
        <input
          name="email"
          type="text"
          placeholder="EMAIL"
          onChange={removeError}
        />
        <div className="d-flex gap-2">
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="PASSWORD"
            onChange={removeError}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            disabled={loading}
            onClick={togglePassword}
          >
            {!showPassword ? (
              <i className="fas fa-eye"></i>
            ) : (
              <i className="fas fa-eye-slash"></i>
            )}
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center small">
          {/* <div className="form-check">
            <input id="remember-me" className="form-check-input" type="checkbox" />
            <label className="form-check-label" htmlFor="remember-me">
              Remember me
            </label>
          </div> */}
          <div className="text-sm text-secondary me-2">
            Can't access your account?
          </div>
          <div
            role="button"
            className="text-primary fw-medium"
            onClick={events?.onForgotPasswordClick}
          >
            Forgot password
          </div>
        </div>
        <div className="mt-2">
          <button
            className="primary-btn-fill w-100"
            disabled={loading}
            type="submit"
          >
            Login{" "}
            {loading && (
              <div
                className="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
        <div className="d-flex align-items-center gap-1">
          <span className="fw-medium small">Not registered?</span>
          <div
            className="text-primary fw-medium small ms-1"
            role="button"
            onClick={events?.onRegisterClick}
          >
            Create Account
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default withRouter(ModalLogin);
