import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { subscribeNewsletter } from "../../services/subscribe";
import { fetchContactContent } from "../../services/contents";

class HomeOneFooter extends Component {
  constructor() {
    super();
    this.state = {
      contactData: [],
    };
  }
  componentDidMount() {
    this.getContactContent();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  async handleSubscribeNewsletter(e) {
    e.preventDefault();
    const email = e.target.elements.email.value;
    if (!email) {
      return;
    }
    try {
      await subscribeNewsletter(email);
      e.target.reset();
      toast.success("Subscribed successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to subscribe");
    }
  }
  async getContactContent() {
    try {
      const response = await fetchContactContent();
      if (response.status === 200 || response.status === 201) {
        const data = response.data?.data;
        this.setState({ contactData: data });
      }
    } catch (error) {
      console.error(error);
    }
  }
  findContactContentType(type) {
    const { contactData } = this.state;
    return contactData.find((item) => item.type === type);
  }
  render() {
    return (
      <>
        {/* ===============  footer area start  =============== */}
        <div className="footer-outer pt-120 gray-300">
          <div className="footer-area">
            <div className="container">
              <div className="footer-wrapper">
                <div className="footer-watermark">
                  <h1>Wii Union</h1>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-3 col-md-6 col-sm-5 order-1">
                    <div className="footer-widget">
                      <h5 className="footer-widget-title">Quick Link</h5>
                      <div className="footer-links">
                        <ul className="link-list">
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/about`}
                              className="footer-link"
                            >
                              About Us
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/event`}
                              className="footer-link"
                            >
                              Event
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/schedule`}
                              className="footer-link"
                            >
                              Schedule
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/contact`}
                              className="footer-link"
                            >
                              Our Support
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/privacy-policy`}
                              className="footer-link"
                            >
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                              className="footer-link"
                            >
                              Terms & Conditions
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/speaker`}
                              className="footer-link"
                            >
                              Speaker
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/sponsor-area`}
                              className="footer-link"
                            >
                              Sponsor
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link
                              onClick={this.scrollTop}
                              to={`${process.env.PUBLIC_URL}/blog`}
                              className="footer-link"
                            >
                              Blog
                            </Link>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-3 d-flex justify-content-center">
                    <div className="footer-newslatter-wrapper text-center">
                      <h3>Subscribe Our Newsletter</h3>
                      <h5>Don’t Miss Our Feature Update</h5>
                      <form
                        className="newslatter-form"
                        id="newslatter-form"
                        onSubmit={this.handleSubscribeNewsletter}
                      >
                        <div className="newslatter-input-group d-flex">
                          <input
                            name="email"
                            type="email"
                            placeholder="Enter Your Email"
                          />
                          <button type="submit">Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-7 order-lg-3 order-2 ">
                    <div className="footer-widget">
                      <h5 className="footer-widget-title">Contact</h5>
                      <div className="footer-links">
                        <ul className="link-list">
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-telephone-plus" />
                            </div>
                            <div className="contact-links">
                              <a
                                href={`tel:${
                                  this.findContactContentType("EMAIL")?.content
                                }`}
                              >
                                {this.findContactContentType("PHONE")?.content}
                              </a>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-envelope-open" />
                            </div>
                            <div className="contact-links">
                              <a
                                href={`mailto:${
                                  this.findContactContentType("EMAIL")?.content
                                }`}
                              >
                                {this.findContactContentType("EMAIL")?.content}
                              </a>
                            </div>
                          </li>
                          <li className="contact-box">
                            <div className="contact-icon">
                              <i className="bi bi-geo-alt" />
                            </div>
                            <div className="contact-links">
                              <a>
                                {
                                  this.findContactContentType("LOCATION")
                                    ?.content
                                }
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bottom">
                <div className="row align-items-center">
                  <div className="col-lg-5  order-3 order-lg-1">
                    <div className="footer-copyright">
                      <p>
                        Copyright 2024 Wii Union
                        {/* | Design By <a>Egens Lab</a> */}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 order-1 order-lg-2">
                    <div className="footer-logo">
                      <Link onClick={this.scrollTop} to={"#"}>
                        <img src="assets/images/logo-horizontal.png" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 order-2 order-lg-3">
                    {/* <ul className="d-flex footer-social-links justify-content-lg-end justify-content-center">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-whatsapp" />
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  footer area end  =============== */}
      </>
    );
  }
}

export default HomeOneFooter;
