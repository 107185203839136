import { useEffect, useState } from "react";
import subBanner from "../../../assets/images/banners/sb-banner.png";
const PromotionBannerArea = (props) => {
  const { data: banners } = props;
  const [randomBanner, setRandomBanner] = useState();
  useEffect(() => {
    if (!randomBanner) setRandomBanner(banners[0]);
    const bannerInterval = setInterval(() => {
      const index = Math.floor(Math.random() * banners.length) % banners.length;
      setRandomBanner(banners[index]);
    }, 10000); // change banner every 10 seconds
    return () => clearInterval(bannerInterval);
  }, [banners]);
  return (
    <div className="sb-banner">
      <a href={randomBanner?.action_url} target="_blank" rel="noreferrer">
        <img
          src={randomBanner?.image || subBanner}
          alt="Imgs"
          className="sb-banner-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = subBanner;
          }}
        />
      </a>
    </div>
  );
};

export default PromotionBannerArea;
