import axios from "axios";
import api from "../utils/api";

export const createEventTransactions = async (data) => {
  const URL = `${api.transactions}/v2/submit`;
  const token = localStorage.getItem("token");
  return axios.post(URL, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getMemberTransaction = async (id) => {
  const URL = `${api.transactions}/getByMember`;
  const token = localStorage.getItem("token");
  return axios.post(URL, undefined, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
