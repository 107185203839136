import React, { Component } from "react";
// Swiper slider imported
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import SpeakerIMG1 from "../../../assets/images/speaker/speaker-1.png";
import { fetchEventCollections } from "../../../services/events";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);
class CollectionArea extends Component {
  constructor() {
    super();
    this.state = {
      collections: [],
    };
  }
  componentDidMount() {
    this.getEventCollections();
  }
  async getEventCollections() {
    try {
      const response = await fetchEventCollections();
      this.setState({ collections: response.data.data });
    } catch (e) {
      console.log(e);
    }
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    const { collections } = this.state;
    const showedCollection = collections.filter(
      (collection) => collection.image
    );
    const speakerSlider = {
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 24,
      // loop: true,
      roundLengths: true,
      autoplay: {
        delay: 15000,
      },
      pagination: {
        el: ".speaker-two-pagination",
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    };
    if (!showedCollection.length) return null;
    return (
      <>
        {/* ===============  Speakers area starts  =============== */}
        <div className="speakers-area mt-4">
          <div className="container position-relative pt-110 pb-4">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="background-title text-style-one">
                  <h2>Collection</h2>
                </div>
                <div className="section-head">
                  <h5>Collection</h5>
                  <h3>Our Collection</h3>
                </div>
              </div>
            </div>
            <div className="swiper speaker-slider-two">
              <Swiper {...speakerSlider} className="swiper-wrapper">
                {showedCollection.map((collection) => (
                  <SwiperSlide
                    key={`collection-${collection.id}`}
                    className="swiper-slide"
                  >
                    <Link
                      className="speaker-card"
                      to={`${process.env.PUBLIC_URL}/event-collection/${collection.id}`}
                    >
                      <div className="speaker-image">
                        <img
                          src={collection.image || SpeakerIMG1}
                          width="350px"
                          height="400px"
                          alt="SpeakerIMG"
                        />
                        <div className="collection-name">{collection.name}</div>
                        {/* <ul className="speaker-social-icons">
                        <li>
                          <Link onClick={this.scrollTop} to={"#"}>
                            <i className="fab fa-facebook-f" />
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.scrollTop} to={"#"}>
                            <i className="fab fa-instagram" />
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.scrollTop} to={"#"}>
                            <i className="fab fa-linkedin-in" />
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.scrollTop} to={"#"}>
                            <i className="fab fa-twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.scrollTop} to={"#"}>
                            <i className="fab fa-whatsapp" />
                          </Link>
                        </li>
                      </ul> */}
                      </div>
                      <div className="speaker-info">
                        <h5 className="speaker-name">
                          <div>{collection.name}</div>
                        </h5>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="speaker-two-pagination d-lg-flex d-none" />
            </div>
          </div>
        </div>
        {/* ===============  Speakers area end  =============== */}
      </>
    );
  }
}

export default CollectionArea;
