import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
/*image light box*/
import SimpleReactLightbox from "simple-react-lightbox";
/*import wrapping layout*/
import Layout from "./components/App";
// layout default import from compnent
import MainLayout from "./components/layout/Main";
import MainTwoLayout from "./components/layout/Main-two";
/*import all pages from components*/
import Error from "./components/pages/404/Error";
import About from "./components/pages/about/About";
import ContactPage from "./components/pages/contact/ContactPage";
import EventDetails from "./components/pages/events/eventDetails/EventDetails";
import EventSidebar from "./components/pages/events/EventSidebar";
import EventCollection from "./components/pages/events/EventsCollection";
import FAQ from "./components/pages/Faq/FAQ";
import Gallary from "./components/pages/Gallary/Gallary";
import Pricing from "./components/pages/pricing/Pricing";
import Schedule from "./components/pages/Schedule/Schedule";
import Speaker from "./components/pages/speakers/Speaker";
import SpeakersDetails from "./components/pages/speakers/SpeakersDetails";
import SpeakerTopBar from "./components/pages/speakers/SpeakerTopBar";
import CheckoutPage from "./components/pages/checkout/CheckoutPage";
/*initialization All css*/
import "./index.css";
import { isAuthenticated } from "./utils/auth-check";
import { fetchMemberWishlist } from "./services/wishlist";
import { useAtom } from "jotai";
import { wishlistAtom } from "./store/Wishlist";
import "react-toastify/dist/ReactToastify.css";
import { cartAtom } from "./store/Cart";
import { fetchCarts } from "./services/carts";
import TermsAndConditionsPage from "./components/pages/tnc/TermsAndConditionPage";
import PrivacyPolicyPage from "./components/pages/privacyPolicy/PrivacyPolicy";

// default Warning Error hide
// console.log = console.warn = console.error = () => {};

/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

const Root = () => {
  const [wishlist, setWishlist] = useAtom(wishlistAtom);
  const [cart, setCart] = useAtom(cartAtom);
  const authenticated = isAuthenticated();
  useEffect(() => {
    if (isAuthenticated) {
      getWishlist();
      getCarts();
    }
    if (!authenticated) {
      localStorage.removeItem("user");
      setWishlist([]);
    }
  }, [isAuthenticated]);
  const getWishlist = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      try {
        const response = await fetchMemberWishlist();
        if (response.status === 200) {
          setWishlist(response.data.data);
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 404) setWishlist([]);
      }
    }
  };
  const getCarts = async () => {
    try {
      const response = await fetchCarts();
      if (response.status === 200) {
        setCart(response.data.data.events);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <BrowserRouter basename="/">
        <Switch>
          <Route exact path="/" component={MainLayout} />
          <Route
            path={`${process.env.PUBLIC_URL}/home-page-2`}
            component={MainTwoLayout}
          />
          <Layout>
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/about`}
                component={About}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/contact`}
                component={ContactPage}
              />

              {/* blog pages componets */}
              {/* <Route
                  path={`${process.env.PUBLIC_URL}/blog`}
                  component={Blog}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/blog-details`}
                  component={BlogDetails}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/blog-sidebar`}
                  component={BlogSidebar}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/blog-standard`}
                  component={BlogStandard}
                /> */}

              <Route
                path={`${process.env.PUBLIC_URL}/speaker`}
                component={Speaker}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/speaker-topbar`}
                component={SpeakerTopBar}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/speaker-details`}
                component={SpeakersDetails}
              />

              {/* Event pages components */}
              <Route
                path={`${process.env.PUBLIC_URL}/event-collection/:id`}
                component={EventCollection}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/event`}
                component={EventSidebar}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/event-details/:id`}
                component={EventDetails}
              />

              {/* page menu components */}
              <Route
                path={`${process.env.PUBLIC_URL}/schedule`}
                component={Schedule}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/gallary`}
                component={Gallary}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/pricing`}
                component={Pricing}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/checkout/:id/:timeslot`}
                component={CheckoutPage}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                component={TermsAndConditionsPage}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/privacy-policy`}
                component={PrivacyPolicyPage}
              />
              <Route path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
              <Route path={"*"} component={Error} />
            </Switch>
          </Layout>
        </Switch>
      </BrowserRouter>
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Root />
      <ToastContainer />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);
