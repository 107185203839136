import React, { useEffect } from "react";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// image import
import HIWIcon1 from "../../../assets/images/icons/find-event.png";
import HIWIcon2 from "../../../assets/images/icons/book-ticket.png";
import HIWIcon3 from "../../../assets/images/icons/take-ticket.png";
const HowItWorks = () => {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  return (
    <>
      {/* ===============  How It Works area start =============== */}
      <div className="how-it-works-area">
        <div className="container pt-110 position-relative">
          <div className="row">
            <div className="col-lg-12">
              <div className="background-title text-style-one">
                <h2>How It Works</h2>
              </div>
              <div className="section-head">
                <h3>How It Works</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-6 wow fadeInLeft animated"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="work-card text-center">
                <div className="work-card-icon">
                  <img src={HIWIcon1} alt="Imgs" />
                </div>
                <h3 className="work-card-title">Choose What To Do</h3>
                <p className="work-card-info">
                  Easily find your event via search system with multiple params.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="work-card text-center wow fadeInUp animated"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="work-card-icon">
                  <img src={HIWIcon2} alt="Imgs" />
                </div>
                <h3 className="work-card-title">Booking event that you like</h3>
                <p className="work-card-info">
                  Choose Ticket add to cart. Support payment via Woocommerce
                  system.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInRight animated"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="work-card text-center">
                <div className="work-card-icon">
                  <img src={HIWIcon3} alt="Imgs" />
                </div>
                <h3 className="work-card-title">Get the ticket to attend</h3>
                <p className="work-card-info">
                  After booking successfully, You will get ticket in email or
                  download in your account.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  How It Works area end =============== */}
    </>
  );
};

export default HowItWorks;
