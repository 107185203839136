import axios from "axios";
import api from "../utils/api";

export const fetchOnlinePaymentMethods = async (query) => {
  const URL = api.onlinePayments;
  return axios.get(URL, { params: query });
};

export const createPaymentIntent = async (method, data) => {
  const URL = `${api.onlinePayments}/${method}/createIntent`;
  return axios.post(URL, data);
};
