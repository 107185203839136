import axios from "axios";
import api from "../utils/api";

export const fetchMemberProfile = async () => {
  const URL = api.memberProfile;
  const token = localStorage.getItem("token");
  return axios.post(URL, undefined, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const updateMemberPassword = async (payload) => {
  const URL = api.changePassword;
  const token = localStorage.getItem("token");
  return axios.post(URL, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
export const updateMemberProfile = async (payload) => {
  const URL = api.updateProfile;
  const token = localStorage.getItem("token");
  return axios.post(URL, payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
