import axios from "axios";
import api from "../utils/api";

export const fetchEvents = async (query) => {
  const URL = api.events;
  return axios.get(URL, { params: query });
};

export const fetchUpcomingEvents = async (payload) => {
  const URL = `${api.events}/findUpcoming`;
  return axios.post(URL, payload);
};

export const fetchEventDetails = async (id) => {
  const URL = `${api.events}/${id}`;
  return axios.get(URL);
};

export const fetchEventCategories = async () => {
  const URL = api.eventCategories;
  return axios.get(URL);
};

export const fetchEventTags = async () => {
  const URL = api.eventTags;
  return axios.post(URL);
};

export const fetchEventCollections = async () => {
  const URL = api.eventCollections;
  return axios.get(URL);
};
export const fetchEventCollectionsById = async (id) => {
  const URL = `${api.eventCollections}/${id}`;
  return axios.get(URL);
};

export const fetchEventReviewById = async (payload) => {
  const URL = `${api.eventReview}/getByEventId`;
  return axios.post(URL, payload);
};

export const createEventReview = async (payload) => {
  const URL = api.eventReview;
  return axios.post(URL, payload);
};

export const eventViewAdd = async (id) => {
  const URL = `${api.events}/addView/${id}`;
  return axios.post(URL);
};

export const fetchEventOrganizerById = async (id) => {
  const URL = `${api.eventOrganizer}/${id}`;
  return axios.get(URL);
};
