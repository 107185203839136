import Modal from "./Modal";
const ModalChooseTicket = ({
  isOpen = false,
  ticketData,
  onClose,
  onBack,
  onSelectTicket,
}) => {
  return (
    <Modal
      title={
        <>
          {!!onBack && (
            <i
              role="button"
              class="bi bi-chevron-left fs-5 me-2"
              onClick={() => {
                onBack();
              }}
            ></i>
          )}
          Choose Ticket
        </>
      }
      isOpen={isOpen}
      containerClass={"choose-ticket-modal-container"}
      onClose={onClose}
    >
      <div className="choose-ticket-container font-barlow">
        {ticketData?.length ? (
          <div className="d-flex flex-column gap-3">
            {ticketData.map((item, index) => (
              <div key={index} className="w-100 card">
                <div className="card-header py-3">
                  <h5 className="fw-medium">{item.name}</h5>
                </div>
                <div className="card-body d-flex align-items-center justify-content-between gap-2 w-100">
                  <div className="col-7">
                    <div className="d-flex gap-2 align-items-center">
                      <h6 className="fw-bold" style={{ color: "#702482" }}>
                        HKD ${item.price}
                      </h6>
                    </div>
                    <p className="text-muted">
                      Ticket Available: {item.total_ticket}
                    </p>
                  </div>
                  <button
                    className="btn primary-btn-fill btn-sm col"
                    disabled={item.total_ticket < 1}
                    onClick={() =>
                      item.total_ticket > 0 && onSelectTicket(item)
                    }
                  >
                    {item.total_ticket < 1 ? "Sold Out" : "Select Ticket"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">No ticket available</div>
        )}
      </div>
      <div className="w-100 mt-5">
        <button
          className="primary-btn-outline w-100 btn btn-sm"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ModalChooseTicket;
