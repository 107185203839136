import { isJSON } from "validator";

export const isAuthenticated = () => {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }
    const expiresAt = user && localStorage.getItem('expires_at');
    if (expiresAt && new Date(expiresAt).getTime() > new Date().getTime()) {
        return true;
    }
    return false;
}