import axios from "axios";
import api from "../utils/api";

export const fetchBanners = async () => {
  const URL = api.banners;
  return axios.post(URL);
};
export const fetchPromotionBanners = async () => {
  const URL = api.promotionBanners;
  return axios.get(URL);
};
