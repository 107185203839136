import moment from "moment";
import Modal from "./Modal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutStripe from "../CheckoutStripe";
import { loadStripe } from "@stripe/stripe-js";
import { fetchOnlinePaymentMethods } from "../../../services/payments";
import { createEventTransactions } from "../../../services/transactions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
// import images
import CreditCard from "../../../assets/images/payments/credit-card.png";
import PaymentSuccess from "../../../assets/images/icons/payment-success.svg";
import { useAtom } from "jotai";
import { cartAtom } from "../../../store/Cart";
import { addToCart, fetchCarts } from "../../../services/carts";
import { toast } from "react-toastify";
import { HKD_MINIMUM_PAYMENT_AMOUNT } from "../../../constants";

const ModalBookingOverview = ({
  isOpen = false,
  data: bookingData,
  onClose,
  ...props
}) => {
  const [participants, setParticipants] = useState([]);
  const [ticketQty, setTicketQty] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [stripeData, setStripeData] = useState("");
  const [cart, setCart] = useAtom(cartAtom);
  const [step, setStep] = useState(1);
  const [options, setOptions] = useState({
    clientSecret: "",
  });
  const [loading, setLoading] = useState(false);
  const [useProfileData, setUseProfileData] = useState([]);
  const submitTicketRef = useRef(null);
  const checkoutRef = useRef(null);

  const stripePromise = useMemo(
    () => stripeData.public_key && loadStripe(stripeData.public_key),
    [stripeData.public_key]
  );
  const discount = useMemo(
    () => (bookingData.ticket?.price || 0) * ((user?.tier?.amount || 0) / 100),
    [user]
  );
  const total = useMemo(
    () =>
      (
        (Number(bookingData?.ticket?.price || 0) +
          (stripeData?.fee_amount || 0) -
          discount) *
        ticketQty
      ).toFixed(2),
    [discount, stripeData, ticketQty]
  );
  useEffect(() => {
    isOpen && getOnlinePayment();
  }, [isOpen]);

  useEffect(() => {
    const search = Object.fromEntries(
      new URLSearchParams(props.history.location.search)
    );
    const defaultTicket =
      bookingData.ticket?.total_ticket < 1 ? 0 : search.qty || 1;
    setTicketQty(defaultTicket);
    // setUseProfileData(Array.from(Array(Number(defaultTicket)).keys()));

    const findTimeslot = bookingData.event?.timeslots?.find(
      (timeslot) => timeslot.id === Number(search.timeslot)
    );
    const isEventNotAvailableToBook =
      findTimeslot && moment().isAfter(findTimeslot.start_date);
    if (isEventNotAvailableToBook) {
      toast.error("Event is not available to book");
      props.history.push(`/events/${bookingData.event.id}`);
      onClose();
    }
  }, [props.history.location.search, bookingData]);

  const getOnlinePayment = async () => {
    try {
      const query = {
        status: 1,
        q: "Stripe",
      };
      const response = await fetchOnlinePaymentMethods(query);
      const { data } = response.data;
      setStripeData(data[0]);
      setOptions((prev) => ({ ...prev, clientSecret: data[0].secret_key }));
    } catch (error) {
      console.error(error);
    }
  };
  const transactionPayload = useCallback(
    (participants, ticketQty = 1) => ({
      items: [
        {
          event_id: bookingData.event.id,
          event_ticket_id: bookingData.ticket.id,
          event_timeslot_id: bookingData.timeslot.id,
          quantity: Number(ticketQty),
          original_price: bookingData.ticket.price,
          price: bookingData.ticket.price,
          currency: "HKD",
          participants,
        },
      ],
      discounts: {
        coupon_discount: null,
        member_coupon_id: null,
        coupon_id: null,
        discount_code: null,
        total_discount_code: 0,
        total_discount_tier: discount * ticketQty,
        total_discount_voucher: 0,
        voucher_id: null,
        member_voucher_id: null,
      },
      pricing: {
        total_initial_price: bookingData.ticket.price * ticketQty,
        total_purchase: Number(total),
        subtotal_after_discount_tier: Number(total),
      },
      payment:
        Number(total) !== 0
          ? {
              method: "ONLINE",
              option_id: stripeData.id,
              identity_id: "",
              status: "WAITING",
            }
          : null,
      contact: {
        firstname: user.name || "",
        lastname: user.name || "",
        email: user.email || "",
        phone: user.phone || "",
      },
      payer_answers: [],
    }),
    [bookingData, discount, stripeData, total, user]
  );
  const handleTransaction = async (payload, isPaid = false) => {
    setLoading(true);
    try {
      const response = await createEventTransactions(payload);
      const { intent } = response.data.data;

      if (isPaid) return;

      total > 0 &&
        setOptions((prev) => ({ ...prev, clientSecret: intent.client_secret }));
      Number(total) === 0 ? setStep(4) : setStep(3);
    } catch (error) {
      console.error(error);
      toast.error(
        error.response.data.message || "Failed to create transaction"
      );
    } finally {
      setLoading(false);
    }
  };
  const onPaymentSuccess = () => {
    setStep(4);
    getCarts();
    setLoading(false);
  };
  const getCarts = async () => {
    try {
      const response = await fetchCarts();
      if (response.status === 200) {
        setCart(response.data.data.events);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddToCart = async () => {
    try {
      await addToCart({
        event_id: bookingData.event.id,
        event_ticket_id: bookingData.ticket.id,
        event_timeslot_id: bookingData.timeslot.id,
        qty: ticketQty,
      });
      toast.success("Event added to cart");
      getCarts();
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message || "Failed to add event to cart");
    }
  };

  const handleTicketQty = (e) => {
    const totalTicket = bookingData.ticket?.total_ticket;
    const ticketQty = Number(e.target.value);
    if (ticketQty > totalTicket) return setTicketQty(totalTicket);
    if (Number(e.target.value) < 1) return setTicketQty(1);
    setTicketQty(ticketQty);
  };

  const handleClose = () => {
    onClose();
    setStep(1);
    setStripeData("");
    setUseProfileData([]);
    setParticipants([]);
  };

  const handleSubmitTicket = async (e) => {
    e.preventDefault();
    let withProfileData = {};
    if (useProfileData.length) {
      withProfileData = participants?.map((el, idx) => {
        if (!useProfileData.includes(idx)) return el;
        return {
          firstname: user.name || "",
          lastname: user.name || "",
          email: user.email || "",
          phone: user.phone || "",
        };
      });
    }
    const payloadData =
      useProfileData.length > 0 ? withProfileData : participants;
    handleTransaction(transactionPayload(payloadData, ticketQty));
  };

  return (
    <Modal
      title={
        <div>
          {step === 2 && (
            <i
              role="button"
              class="bi bi-chevron-left fs-5 me-2"
              onClick={() => {
                setStep(step - 1 || 1);
                setParticipants([]);
              }}
            ></i>
          )}
          {step === 1
            ? "Booking Overview"
            : step === 2
            ? "Participants"
            : step === 3
            ? "Payment"
            : ""}
        </div>
      }
      hideClose
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <div className="booking-overview-container font-barlow">
        {step === 1 ? (
          <>
            <div className="card">
              <div className="card-body">
                <ul>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong className="col-5">Event Name</strong>
                    <span className="text-end">{bookingData.event.name}</span>
                  </li>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong className="col-5">Member ID</strong>
                    <span className="text-end">{user?.member_no}</span>
                  </li>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong>Membership Tier</strong>
                    <span>{user?.tier?.name}</span>
                  </li>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong className="col-5">Date</strong>
                    <span className="text-end">
                      {moment(bookingData?.timeslot?.start_date).format(
                        "DD MMMM YYYY"
                      )}
                    </span>
                  </li>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong className="col-5">Time</strong>
                    <span>
                      {moment(bookingData?.timeslot?.start_date).format(
                        "H:mm A"
                      )}
                    </span>
                  </li>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong className="col-5">Ticket Name</strong>
                    <span className="text-end">{bookingData.ticket?.name}</span>
                  </li>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong className="col-5">Ticket Available</strong>
                    <span>{bookingData.ticket?.total_ticket || 0}</span>
                  </li>
                  <li className="py-1 d-flex justify-content-between align-items-center">
                    <strong className="col-5">Quantity</strong>
                    <div>
                      <input
                        value={ticketQty}
                        type="number"
                        className="text-end"
                        style={{ maxWidth: "120px" }}
                        onChange={handleTicketQty}
                      />{" "}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="my-3">
              <input type="text" placeholder="Enter your promo code" />
            </div> */}
            <ul>
              <li className="mt-3 py-1 d-flex justify-content-between">
                <strong>Item Subtotal</strong>
                <span>
                  HKD $
                  {(
                    Number(bookingData?.ticket?.price || 0) * ticketQty
                  ).toFixed(2)}
                </span>
              </li>
              <li className="py-1 d-flex justify-content-between">
                <strong>Transaction Fee</strong>
                <span>HKD ${stripeData.fee_amount?.toFixed(2)}</span>
              </li>
              <li className="py-1 d-flex justify-content-between">
                <strong>Discount</strong>
                <span>HKD ${(discount * ticketQty).toFixed(2)}</span>
              </li>
              <li className="py-1 d-flex justify-content-between">
                <strong>Total</strong>
                <span>HKD ${total}</span>
              </li>
            </ul>
            {total > 0 && (
              <div className="my-3 payment-method-container">
                <p className="mb-2 fw-medium">Payment Method</p>
                {stripeData ? (
                  <ul className="">
                    <li>
                      <input
                        type="radio"
                        className="btn-check w-fit"
                        name="options"
                        id="option1"
                        autoComplete="off"
                        checked
                      />
                      <label
                        className="payment-radio-button w-100 d-flex justify-content-between"
                        htmlFor="option1"
                      >
                        Credit Card
                        <img src={CreditCard} alt="credit-card" />
                      </label>
                    </li>
                  </ul>
                ) : (
                  <div className="text-center">No payment method available</div>
                )}
              </div>
            )}
            {/* ticket sold out alert */}
            {bookingData.ticket?.total_ticket < 1 && (
              <div className="alert alert-danger">
                <p className="mb-0">Ticket Sold Out</p>
              </div>
            )}
            {total <= HKD_MINIMUM_PAYMENT_AMOUNT ||
              (Number(total) === 0 && (
                <div className="alert alert-warning">
                  <p className="mb-0">
                    Cannot book this ticket. <br />
                    Minimum payment amount is HKD ${HKD_MINIMUM_PAYMENT_AMOUNT}
                  </p>
                </div>
              ))}
          </>
        ) : step === 2 ? (
          <>
            <form onSubmit={handleSubmitTicket}>
              {participants.map((contact, no) => (
                <div key={`ticket-${no}`} className="card participant-form">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="card-title">Ticket {no + 1}</h5>
                    <div className="d-flex align-items-center gap-2">
                      <label htmlFor={`ticket-${no}`} className="mb-1">
                        Use profile data
                      </label>
                      <div>
                        <input
                          id={`ticket-${no}`}
                          value={
                            useProfileData.find((el) => el === no) || false
                          }
                          type="checkbox"
                          onChange={(e) =>
                            setUseProfileData((prev) => {
                              const newData = [...prev];
                              if (e.target.checked) {
                                newData.push(no);
                              }
                              if (!e.target.checked) {
                                newData.splice(newData.indexOf(no), 1);
                              }
                              return newData;
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`card-body ${
                      useProfileData.includes(no) ? "disabled" : ""
                    }`}
                  >
                    <div className="d-flex gap-2">
                      <div className="mb-3">
                        <label htmlFor="first-name" className="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          disabled={useProfileData.includes(no)}
                          value={
                            useProfileData.includes(no)
                              ? user.name || ""
                              : participants[no]?.firstname
                          }
                          onChange={(e) =>
                            setParticipants((prev) => {
                              const newData = [...prev];
                              newData[no].firstname = e.target.value;
                              return newData;
                            })
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="last-name" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          required
                          disabled={useProfileData.includes(no)}
                          value={
                            useProfileData.includes(no)
                              ? user.name || ""
                              : participants[no]?.lastname
                          }
                          onChange={(e) =>
                            setParticipants((prev) => {
                              const newData = [...prev];
                              newData[no].lastname = e.target.value;
                              return newData;
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        required
                        disabled={useProfileData.includes(no)}
                        value={
                          useProfileData.includes(no)
                            ? user.email || ""
                            : participants[no]?.email
                        }
                        onChange={(e) =>
                          setParticipants((prev) => {
                            const newData = [...prev];
                            newData[no].email = e.target.value;
                            return newData;
                          })
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">
                        Phone
                      </label>
                      <input
                        type="tel"
                        required
                        disabled={useProfileData.includes(no)}
                        className="form-control"
                        value={
                          useProfileData.includes(no)
                            ? user.phone || ""
                            : participants[no]?.phone
                        }
                        onChange={(e) =>
                          setParticipants((prev) => {
                            const newData = [...prev];
                            newData[no].phone = e.target.value;
                            return newData;
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="submit"
                className="d-none"
                ref={submitTicketRef}
              ></button>
            </form>
          </>
        ) : step === 3 ? (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutStripe
              onSuccess={() => {
                onPaymentSuccess();
              }}
              onFailed={() => setLoading(false)}
            >
              <button
                style={{ display: "none" }}
                ref={checkoutRef}
                type="submit"
              ></button>
            </CheckoutStripe>
          </Elements>
        ) : (
          <>
            <div className="text-center">
              <img src={PaymentSuccess} alt="payment-success" />
              <h5>Booking Completed</h5>
              <p>We sent details of the booking to your email</p>
            </div>
          </>
        )}
        <div className="w-100 mt-4">
          {step === 1 && (
            <button
              className="btn btn-sm primary-btn-fill w-100 mb-3"
              disabled={
                Number(total) !== 0 &&
                (bookingData.ticket?.total_ticket < 1 ||
                  total <= HKD_MINIMUM_PAYMENT_AMOUNT)
              }
              onClick={() => {
                if (
                  Number(total) !== 0 &&
                  (bookingData.ticket?.total_ticket < 1 ||
                    total <= HKD_MINIMUM_PAYMENT_AMOUNT)
                )
                  return;
                for (let i = 0; i < ticketQty; i++) {
                  setParticipants((prev) => [
                    ...prev,
                    {
                      firstname: "",
                      lastname: "",
                      email: "",
                      phone: "",
                    },
                  ]);
                }
                setStep(2);
              }}
            >
              Book Now
            </button>
          )}
          {step === 2 && (
            <button
              className="btn btn-sm primary-btn-fill w-100 mb-3"
              onClick={() => {
                !loading && submitTicketRef.current.click();
              }}
              disabled={loading}
            >
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          )}
          {step === 3 && (
            <button
              className="btn btn-sm primary-btn-fill w-100 mb-3"
              onClick={() => {
                !loading && checkoutRef.current.click();
                setLoading(true);
              }}
              disabled={loading}
            >
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Pay Now"
              )}
            </button>
          )}
          <div className="d-flex gap-3">
            {step === 1 && (
              <button
                className="btn btn-sm primary-btn-fill col"
                disabled={bookingData.ticket?.total_ticket < 1}
                onClick={() => {
                  bookingData.ticket?.total_ticket > 0 && handleAddToCart();
                  handleClose();
                }}
              >
                <i className="bi bi-cart-plus me-1"></i>
                Add to Cart
              </button>
            )}
            <button
              className="btn btn-sm primary-btn-outline col"
              type="button"
              onClick={() => {
                handleClose();
              }}
            >
              {step === 4 ? "Close" : "Cancel"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(ModalBookingOverview);
