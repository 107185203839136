import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import BreadCrumb from "./BreadCrumb";
import EventDetailsWrapper from "./EventDetailsWrapper";
import { eventViewAdd, fetchEventDetails } from "../../../../services/events";
import Error from "../../404/Error";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import RelatedEvent from "./RelatedEvent";
class EventDetails extends Component {
  constructor() {
    super();
    this.state = {
      eventDetails: {},
      notFound: false,
      loading: true,
    };
  }
  componentDidMount() {
    this.getEventDetails();
    this.addViewEvent();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({ loading: true });
      this.getEventDetails();
      this.addViewEvent();
    }
  }
  async getEventDetails() {
    this.setState({ loading: true });
    try {
      const response = await fetchEventDetails(this.props.match.params.id);
      this.setState({ eventDetails: response.data.data });
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) this.setState({ notFound: true });
      this.setState({ loading: false });
    }
  }
  async addViewEvent() {
    try {
      await eventViewAdd(this.props.match.params.id);
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { eventDetails, notFound, loading } = this.state;
    return (
      <>
        <BreadCrumb data={eventDetails} />
        {notFound ? (
          <Error
            noBreadcrumb
            slots={{
              action: (
                <Link
                  to={`${process.env.PUBLIC_URL}/event`}
                  className="primary-btn-fill"
                >
                  FIND OTHER EVENTS
                </Link>
              ),
            }}
          />
        ) : loading ? (
          <div class="d-flex justify-content-center my-5 py-5">
            <div
              class="spinner-border"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <EventDetailsWrapper data={eventDetails} />
        )}
        <RelatedEvent details={eventDetails} />
      </>
    );
  }
}

export default withRouter(EventDetails);
