import { DEFAULT_HOST } from "../constants";
import { resetPassword } from "../services/auth";

const api = {
  events: DEFAULT_HOST.concat("/events"),
  eventCategories: DEFAULT_HOST.concat("/event-categories"),
  eventCollections: DEFAULT_HOST.concat("/event-collections"),
  eventTags: DEFAULT_HOST.concat("/events/tags"),
  eventReview: DEFAULT_HOST.concat("/event-review"),
  banners: DEFAULT_HOST.concat("/banners/getAvailable"),
  promotionBanners: DEFAULT_HOST.concat("/banner-promotions"),
  contents: DEFAULT_HOST.concat("/contents"),
  register: DEFAULT_HOST.concat("/members/register"),
  login: DEFAULT_HOST.concat("/members/login"),
  resetPassword: DEFAULT_HOST.concat("/members/reset_password"),
  wishlist: DEFAULT_HOST.concat("/event-wishlists"),
  onlinePayments: DEFAULT_HOST.concat("/online-payments"),
  transactions: DEFAULT_HOST.concat("/transactions"),
  carts: DEFAULT_HOST.concat("/carts"),
  memberProfile: DEFAULT_HOST.concat("/members/profile"),
  changePassword: DEFAULT_HOST.concat("/members/change_password"),
  updateProfile: DEFAULT_HOST.concat("/members/updateProfile"),
  contactContents: DEFAULT_HOST.concat("/contents/getContacts"),
  subscribeNewsletter: DEFAULT_HOST.concat("/contents/subscribe"),
  aboutContents: DEFAULT_HOST.concat("/contents/getByContentType"),
  eventOrganizer: DEFAULT_HOST.concat("/event-organizers"),
};

export default api;
