import { useState } from "react";
import { resetPasswordMember } from "../../../services/auth";
import Modal from "./Modal";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
const ModalForgotPassword = ({ isOpen = false, onClose, events, ...props }) => {
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData);
      await resetPasswordMember(data);
      setStep(2);
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const removeError = () => {
    setError(null);
  };
  return (
    <Modal title="Reset Password" isOpen={isOpen} onClose={onClose}>
      {step === 1 && (
        <form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
          {error && (
            <div className="alert alert-danger text-danger small">{error}</div>
          )}
          <span className="text-muted">
            Please enter your email address to reset your password
          </span>
          <input
            name="email"
            type="text"
            placeholder="EMAIL"
            onChange={removeError}
          />
          <div className="d-flex align-items-center small">
            {/* <div className="form-check">
            <input id="remember-me" className="form-check-input" type="checkbox" />
            <label className="form-check-label" htmlFor="remember-me">
              Remember me
            </label>
          </div> */}
            <div className="text-sm text-secondary me-2">Have an account?</div>
            <div
              role="button"
              className="text-primary fw-medium"
              onClick={events?.onLoginClick}
            >
              Login
            </div>
          </div>
          <div className="mt-2">
            <button
              className="primary-btn-fill w-100"
              disabled={loading}
              type="submit"
            >
              Reset Password{" "}
              {loading && (
                <div
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      )}
      {step === 2 && (
        <div className="d-flex flex-column gap-4">
          <h5 className="text-center">Password reset link sent</h5>
          <span className="text-muted text-center">
            We have sent a password reset link to your email address. Please
            check your inbox.
          </span>
          <button
            className="primary-btn-fill w-100"
            onClick={() => {
              setStep(1);
              onClose();
            }}
          >
            Close
          </button>
        </div>
      )}
    </Modal>
  );
};

export default withRouter(ModalForgotPassword);
