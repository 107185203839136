import React, { Component } from "react";
import { Link } from "react-router-dom";
// image import
import Seaker1 from "../../../assets/images/speaker/speaker-1.png";
import Seaker2 from "../../../assets/images/speaker/speaker-2.png";
import Seaker3 from "../../../assets/images/speaker/speaker-3.png";
import { fetchSpeakers } from "../../../services/speakers";
class Speaker extends Component {
  constructor() {
    super();
    this.state = {
      speakers: [],
    };
  }
  componentDidMount() {
    this.getSpeakers();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  async getSpeakers() {
    try {
      const response = await fetchSpeakers();
      if (response.status === 200 || response.status === 201) {
        const speakers = response.data?.data;
        this.setState({ speakers });
      }
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const { speakers } = this.state;
    return (
      <>
        {/* ===============  Speakers area starts  =============== */}
        <div className="speakers-area">
          <div className="container position-relative pt-120">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="background-title text-style-one">
                  <h2>team</h2>
                </div>
                <div className="section-head">
                  <h3>Our Team</h3>
                </div>
              </div>
            </div>
            <div className="row">
              {speakers.map((speaker) => (
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="speaker-card">
                    <div className="speaker-image">
                      <img src={speaker.photo || Seaker1} alt="" />
                      {/* <ul className="speaker-social-icons">
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.scrollTop} to={"#"}>
                          <i className="fab fa-whatsapp" />
                        </Link>
                      </li>
                    </ul> */}
                    </div>
                    <div className="speaker-info">
                      <h5 className="speaker-name">
                        {/* <Link
                        onClick={this.scrollTop}
                        to={`${process.env.PUBLIC_URL}/speaker-details`}>
                        Santiago Axel
                      </Link> */}
                        {speaker.name}
                      </h5>
                      {/* <p>Senio Visual Designer</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* ===============  Speakers area end  =============== */}
      </>
    );
  }
}

export default Speaker;
