import { useEffect, useRef, useState } from "react";
import validator, { isJSON } from "validator";
import Modal from "./Modal";
import {
  updateMemberProfile,
  fetchMemberProfile,
} from "../../../services/member";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
// Import Images
import ProfilePicture from "../../../assets/images/profile-picture.png";
import { toast } from "react-toastify";
import { z } from "zod";
import { renderErrorZod } from "../../../utils";

const accept = "image/png, image/jpeg, image/jpg";

const schema = z.object({
  name: z.string().nonempty("Name cannot be empty"),
  // birth_date: z.string(),
  phone: z
    .string()
    .nonempty("Phone number cannot be empty")
    .refine(validator.isMobilePhone),
});

const ModalMemberProfile = ({ isOpen = false, onClose, ...props }) => {
  const profilePictureRef = useRef(null);

  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user) {
      setProfilePicture(user.photo);
    }
  }, []);

  const removeError = (field) => {
    if (error) {
      const _error = error.filter((e) => !e.path.includes(field));
      setError(_error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const form = e.target;
      const formData = new FormData(form);
      schema.parse(Object.fromEntries(formData));
      const response = await updateMemberProfile(formData);
      if (response.status === 200 || response.status === 201) {
        await getUserProfile();
        setTimeout(() => {
          props.history.go();
        }, 500);
      }
    } catch (error) {
      console.error(error);
      isJSON(error.message)
        ? setError(JSON.parse(error.message))
        : toast.error(
            error?.response?.data?.message || "Failed to update profile"
          );
    }
  };

  const getUserProfile = async () => {
    try {
      const response = await fetchMemberProfile();
      if (response.status === 200 || response.status === 201) {
        const user = response.data?.data;
        localStorage.setItem("user", JSON.stringify(user));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeProfilePicture = (e) => {
    const file = e.target.files[0];
    if (file) {
      // limit file size up to 2MB
      if (file.size > 2 * 1024 * 1024) {
        return toast.error("File size too large. Max 2MB");
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfilePicture(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleRemoveProfilePicture = () => {
    setProfilePicture(null);
    profilePictureRef.current.value = "";
  };
  if (!user) return null;
  return (
    <Modal title="Edit Profile" isOpen={isOpen} onClose={onClose}>
      <form className="edit-profile-container" onSubmit={handleSubmit}>
        <h6 className="fw-bold mb-2">Profile Picture</h6>
        <div className="d-flex gap-3 align-items-center">
          <div>
            <img
              src={profilePicture || ProfilePicture}
              alt="profile"
              className="profile-picture"
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <input
              ref={profilePictureRef}
              defaultValue={profilePicture}
              type="file"
              name="photo"
              className="d-none"
              accept={accept}
              onChange={handleChangeProfilePicture}
            />
            <button
              type="button"
              className="primary-btn-fill"
              onClick={() => profilePictureRef.current.click()}
            >
              Change Photo
            </button>
            <button
              type="button"
              className="primary-btn-outline"
              onClick={handleRemoveProfilePicture}
            >
              Remove Photo
            </button>
          </div>
        </div>
        <h6 className="fw-bold mb-3 mt-4">Personal Information</h6>
        <div className="d-flex flex-column gap-4">
          <div>
            <label htmlFor="name" className="fw-bold small">
              Name
            </label>
            <input
              defaultValue={user.name}
              name="name"
              type="text"
              placeholder="FIRST NAME"
              onChange={() => removeError("name")}
            />
            {renderErrorZod(error, "name")}
          </div>
          <div>
            <label htmlFor="username" className="fw-bold small">
              Username
            </label>
            <input
              defaultValue={user.username}
              name="username"
              type="text"
              placeholder="USERNAME"
            />
          </div>
          <div>
            <label htmlFor="birth_date" className="fw-bold small">
              Birthdate
            </label>
            <input
              defaultValue={user.birth_date}
              name="birth_date"
              type="date"
              placeholder="BIRTHDATE"
              // onChange={() => removeError("birth_date")}
            />
            {/* {renderErrorZod(error, "birth_date")} */}
          </div>
          {/* <div>
            <label htmlFor="email" className="fw-bold small">
              Email
            </label>
            <input defaultValue={user.email} type="text" placeholder="EMAIL" disabled />
          </div> */}
          <div>
            <label htmlFor="phone-number" className="fw-bold small">
              Phone Number
            </label>
            <input
              defaultValue={user.phone}
              name="phone"
              type="text"
              placeholder="PHONE NUMBER"
              onChange={() => removeError("phone")}
            />
            {renderErrorZod(error, "phone")}
          </div>
          <div>
            <label htmlFor="address" className="fw-bold small">
              Address
            </label>
            <textarea
              defaultValue={user.address}
              name="address"
              placeholder="ADDRESS"
              rows={4}
              style={{
                resize: "none",
              }}
            />
          </div>
          <div className="mt-2">
            <button className="primary-btn-fill w-100" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default withRouter(ModalMemberProfile);
