import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../assets/css/animate.css";
// image import
import NavLogo from "../../assets/images/logo-horizontal.png";
import Logo from "../../assets/images/logo-horizontal.png";
import ModalLogin from "./Modals/ModalLogin";
import ModalRegister from "./Modals/ModalRegister";
import ModalMemberProfile from "./Modals/ModalMemberProfile";
import { isAuthenticated } from "../../utils/auth-check";
import ModalWishlist from "./Modals/ModalWishlist";
import { useAtom } from "jotai";
import { wishlistAtom } from "../../store/Wishlist";
import { cartAtom } from "../../store/Cart";
import ModalCart from "./Modals/ModalCart";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import ModalChangePassword from "./Modals/ModalChangePassword";
import ProfilePicture from "../../assets/images/profile-picture.png";
import { initScripts } from "../../utils";
import ModalForgotPassword from "./Modals/ModalForgotPassword";
import ModalMyTransaction from "./Modals/ModalMyTransaction";

const excludedScriptInitialization = ["/event"];

const LayoutHeader = (props) => {
  const authenticated = isAuthenticated();
  const [isOpen, setIsOpen] = React.useState({
    login: false,
    forgotPassword: false,
    register: false,
    memberProfile: false,
    changePassword: false,
    wishlist: false,
    cart: false,
    transactions: false,
  });
  const [wishlist] = useAtom(wishlistAtom);
  const [cart] = useAtom(cartAtom);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (
      props.history.location.pathname &&
      !excludedScriptInitialization.includes(props.history.location.pathname)
    )
      initScripts();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(props.history.location.search);
    if (!authenticated)
      setIsOpen((prev) => ({
        ...prev,
        login: query.get("login") == 1,
      }));
  }, [props.history.location]);

  const toggleLoginModal = () => {
    setIsOpen((prev) => ({
      ...prev,
      login: !prev.login,
    }));
  };
  const toggleForgotPasswordModal = () => {
    setIsOpen((prev) => ({
      ...prev,
      forgotPassword: !prev.forgotPassword,
    }));
  };
  const toggleRegisterModal = () => {
    setIsOpen((prev) => ({
      ...prev,
      register: !prev.register,
    }));
  };
  const toggleMemberProfileModal = () => {
    setIsOpen((prev) => ({
      ...prev,
      memberProfile: !prev.memberProfile,
    }));
  };
  const toggleChangePassword = () => {
    setIsOpen((prev) => ({
      ...prev,
      changePassword: !prev.changePassword,
    }));
  };
  const toggleWishlistModal = () => {
    setIsOpen((prev) => ({
      ...prev,
      wishlist: !prev.wishlist,
    }));
  };
  const toggleCartModal = () => {
    setIsOpen((prev) => ({
      ...prev,
      cart: !prev.cart,
    }));
  };
  const toggleMyTransactionModal = () => {
    setIsOpen((prev) => ({
      ...prev,
      transactions: !prev.transactions,
    }));
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {/* ===============  header area start =============== */}
      <header>
        <div className="header-area header-style-one header-light">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-xl-flex align-items-center">
                <div className="logo d-flex align-items-center justify-content-between">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/`}
                    className="logo-dark"
                  >
                    <img src={Logo} alt="logo" height="48px" />
                  </Link>
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/`}
                    className="logo-white"
                  >
                    <img src={NavLogo} alt="logo" height="48px" />
                  </Link>
                  <div className="mobile-menu d-flex ">
                    <a role="button" className="hamburger d-block d-xl-none">
                      <span className="h-top" />
                      <span className="h-middle" />
                      <span className="h-bottom" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end col-md-10 col-sm-6 col-xs-6">
                <nav className="main-nav">
                  <div className="inner-logo d-xl-none">
                    <Link to={"#"} onClick={scrollTop}>
                      <img src={NavLogo} alt="NavLogo" height="48px" />
                    </Link>
                  </div>
                  <ul>
                    <li className="has-child-menu">
                      <Link to={`${process.env.PUBLIC_URL}/`}>
                        Home <span>01</span>
                      </Link>
                      {/* <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <NavLink
                              to={`${process.env.PUBLIC_URL}/`}
                              className="sub-item"
                              onClick={scrollTop}
                            >
                              Home One
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={`${process.env.PUBLIC_URL}/home-page-2`}
                              className="sub-item"
                              onClick={scrollTop}
                            >
                              Home Two
                            </NavLink>
                          </li>
                        </ul> */}
                    </li>
                    <li>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/about`}
                        // className="sub-item"
                        className="has-child-menu-item"
                        onClick={scrollTop}
                        // id="home"
                      >
                        About <span>02</span>
                      </NavLink>
                    </li>
                    <li className="has-child-menu">
                      <Link
                        className="has-child-menu-item"
                        to={`${process.env.PUBLIC_URL}/event`}
                        onClick={scrollTop}
                      >
                        Events <span>03</span>
                      </Link>
                      {/* <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/event`}
                              onClick={scrollTop}
                            >
                              Event Grid
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/event-sidebar`}
                              onClick={scrollTop}
                            >
                              Event Sidebar
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/event-details`}
                              onClick={scrollTop}
                            >
                              Event Details
                            </Link>
                          </li>
                        </ul> */}
                    </li>
                    {/* <li className="has-child-menu">
                        <Link
                          className="has-child-menu-item"
                          to={"#"}
                          onClick={scrollTop}
                        >
                          Spekars <span>03</span>
                        </Link>
                        <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <NavLink
                              id="speaker"
                              to={`${process.env.PUBLIC_URL}/speaker`}
                              onClick={scrollTop}
                            >
                              Speaker Grid
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              id="speaker"
                              to={`${process.env.PUBLIC_URL}/speaker-topbar`}
                              onClick={scrollTop}
                            >
                              Speaker Topbar
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              id="speaker"
                              to={`${process.env.PUBLIC_URL}/speaker-details`}
                              onClick={scrollTop}
                            >
                              Speaker Details
                            </NavLink>
                          </li>
                        </ul>
                      </li> */}
                    {/* <li className="has-child-menu">
                        <Link
                          className="has-child-menu-item"
                          to={"#"}
                          onClick={scrollTop}
                        >
                          Pages <span>05</span>
                        </Link>
                        <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/schedule`}
                              onClick={scrollTop}
                            >
                              Schedule
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/gallary`}
                              onClick={scrollTop}
                            >
                              Gallary
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/pricing`}
                              onClick={scrollTop}
                            >
                              Pricing
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/faq`}
                              onClick={scrollTop}
                            >
                              FAQ
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/error`}
                              onClick={scrollTop}
                            >
                              404
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                    {/* <li className="has-child-menu">
                      <Link
                        to={`${process.env.PUBLIC_URL}/blog`}
                        className="has-child-menu-item"
                        onClick={scrollTop}
                      >
                        Blog <span>04</span>
                      </Link>
                      <i className="fl flaticon-plus">+</i>
                        <ul className="sub-menu">
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/blog`}
                              onClick={scrollTop}
                            >
                              Blog Grid
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/blog-sidebar`}
                              onClick={scrollTop}
                            >
                              Blog Sidebar
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/blog-standard`}
                              onClick={scrollTop}
                            >
                              Blog Standard
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/blog-details`}
                              onClick={scrollTop}
                            >
                              Blog Details
                            </Link>
                          </li>
                        </ul>
                    </li> */}
                    <li>
                      <Link
                        to={`${process.env.PUBLIC_URL}/contact`}
                        onClick={scrollTop}
                      >
                        Contact <span>04</span>
                      </Link>
                    </li>
                  </ul>
                  {authenticated ? (
                    <div className="d-xl-none">
                      {/* cart */}
                      <div className="user-nav d-flex justify-content-between  align-items-center">
                        <a onClick={toggleCartModal}>
                          <i className="bi bi-bag"></i>Cart
                        </a>
                        {cart.length > 0 && (
                          <span className="counter">{cart?.length || 0}</span>
                        )}
                      </div>
                      {/* wishlist */}
                      <div className="user-nav d-flex justify-content-between  align-items-center">
                        <a onClick={toggleWishlistModal}>
                          <i className="bi bi-heart"></i>Wishlist
                        </a>
                        {wishlist.length > 0 && (
                          <span className="counter">
                            {wishlist?.length || 0}
                          </span>
                        )}
                      </div>
                      {/* profile */}
                      {/* collapse and expand profile */}
                      <div className="user-nav">
                        <div className="dropdown bg-bg-transparent">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="sidebarProfileDropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={user?.photo || ProfilePicture}
                              className="profile-avatar"
                              alt="profile"
                            />
                            <strong className="mx-3">
                              {user?.name
                                ? `${user?.name?.substring(0, 20)}${
                                    user?.name?.length > 20 ? "..." : ""
                                  }`
                                : ""}
                            </strong>
                          </a>

                          <ul
                            className="dropdown-menu bg-transparent mt-3"
                            aria-labelledby="sidebarProfileDropdown"
                          >
                            <li className="mt-2 px-0">
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={toggleMemberProfileModal}
                              >
                                Edit Profile
                              </a>
                            </li>
                            <li className="mt-2 px-0">
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={toggleChangePassword}
                              >
                                Change Password
                              </a>
                            </li>
                            <li className="mt-2 px-0">
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={toggleMyTransactionModal}
                              >
                                My Transaction
                              </a>
                            </li>
                            <hr className="bg-white" />
                            <li className="mt-2 px-0">
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  localStorage.removeItem("user");
                                  window.location.reload();
                                }}
                              >
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="inner-btn d-xl-none">
                        <button
                          // to={`${process.env.PUBLIC_URL}/event-details`}
                          className="primary-btn-fill w-100"
                          onClick={toggleLoginModal}
                        >
                          Login
                        </button>
                        <button
                          // to={`${process.env.PUBLIC_URL}/event-details`}
                          className="primary-btn-outline w-100 mt-3"
                          onClick={toggleRegisterModal}
                        >
                          Register
                        </button>
                      </div>
                    </>
                  )}
                </nav>
                <div className="nav-overlay"></div>
                <div className="ms-5 d-none d-xl-flex align-items-center justify-content-end">
                  <ul
                    className={`d-flex align-items-center ${
                      authenticated ? "gap-4" : "gap-3"
                    }`}
                  >
                    {authenticated ? (
                      <>
                        <>
                          {/* cart */}
                          <li className="nav-icon">
                            <a onClick={toggleCartModal}>
                              {cart?.length > 0 && (
                                <span className="counter">
                                  {cart?.length || 0}
                                </span>
                              )}
                              <i className="bi bi-bag"></i>
                            </a>
                          </li>
                          {/* wishlist */}
                          <li className="nav-icon">
                            <a onClick={toggleWishlistModal}>
                              {wishlist?.length > 0 && (
                                <span className="counter">
                                  {wishlist?.length || 0}
                                </span>
                              )}
                              <i className="bi bi-heart"></i>
                            </a>
                          </li>
                          {/* profile */}
                          <li>
                            {/* Dropdown */}
                            <div className="dropdown">
                              <a
                                className="dropdown-toggle"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={user?.photo || ProfilePicture}
                                  className="profile-avatar"
                                  alt="profile"
                                />
                              </a>

                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item py-2"
                                    href="#"
                                    onClick={toggleMemberProfileModal}
                                  >
                                    Edit Profile
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item py-2"
                                    href="#"
                                    onClick={toggleChangePassword}
                                  >
                                    Change Password
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item pt-2"
                                    href="#"
                                    onClick={toggleMyTransactionModal}
                                  >
                                    My Transaction
                                  </a>
                                </li>
                                <hr />
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                      localStorage.removeItem("user");
                                      window.location.reload();
                                    }}
                                  >
                                    Logout
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </>
                      </>
                    ) : (
                      <>
                        <li className="nav-btn">
                          <button
                            className="primary-btn-fill"
                            // to={`${process.env.PUBLIC_URL}/event-details`}
                            onClick={toggleLoginModal}
                          >
                            Login
                          </button>
                        </li>
                        <li className="nav-btn">
                          <button
                            className="primary-btn-outline"
                            // to={`${process.env.PUBLIC_URL}/event-details`}
                            onClick={toggleRegisterModal}
                          >
                            Register
                          </button>
                        </li>
                        {/* <li className="nav-btn">
                      <button
                        className="primary-btn-outline"
                        // to={`${process.env.PUBLIC_URL}/event-details`}
                        onClick={() => toggleMemberProfileModal()}
                      >
                        Profile
                      </button>
                    </li> */}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* ===============  header area end =============== */}

      {/* ===============  Modal start =============== */}
      <ModalLogin
        isOpen={isOpen.login}
        onClose={toggleLoginModal}
        events={{
          onRegisterClick: () => {
            toggleLoginModal();
            toggleRegisterModal();
          },
          onForgotPasswordClick: () => {
            toggleLoginModal();
            toggleForgotPasswordModal();
          },
        }}
      />
      <ModalForgotPassword
        isOpen={isOpen.forgotPassword}
        onClose={toggleForgotPasswordModal}
        events={{
          onLoginClick: () => {
            toggleForgotPasswordModal();
            toggleLoginModal();
          },
        }}
      />
      <ModalRegister
        isOpen={isOpen.register}
        onClose={toggleRegisterModal}
        events={{
          onLoginClick: () => {
            toggleRegisterModal();
            toggleLoginModal();
          },
        }}
      />
      <ModalMemberProfile
        isOpen={isOpen.memberProfile}
        onClose={toggleMemberProfileModal}
      />
      <ModalChangePassword
        isOpen={isOpen.changePassword}
        onClose={toggleChangePassword}
      />
      <ModalMyTransaction
        isOpen={isOpen.transactions}
        onClose={toggleMyTransactionModal}
      />
      <ModalWishlist isOpen={isOpen.wishlist} onClose={toggleWishlistModal} />
      <ModalCart isOpen={isOpen.cart} onClose={toggleCartModal} />
      {/* ===============  Modal end =============== */}

      {/* <!-- Custom Cursor --> */}

      <div className="cursor"></div>
      <div className="cursor2"></div>

      {/* Custom Cursor End  */}
    </>
  );
};

export default withRouter(LayoutHeader);
