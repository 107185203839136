import axios from "axios";
import api from "../utils/api";

export const fetchCarts = async () => {
  const URL = `${api.carts}`;
  const accessToken = localStorage.getItem("token") || null;
  return axios.get(URL, {
    headers: { Authorization: "Bearer " + accessToken },
  });
};
export const addToCart = async (data) => {
  const URL = `${api.carts}`;
  const accessToken = localStorage.getItem("token") || null;
  return axios.post(URL, data, {
    headers: { Authorization: "Bearer " + accessToken },
  });
};

export const deleteFromCart = async (id) => {
  const URL = `${api.carts}/${id}`;
  const accessToken = localStorage.getItem("token") || null;
  return axios.delete(URL, {
    headers: { Authorization: "Bearer " + accessToken },
  });
};
