import React, { Component } from "react";
import BrandLogo1 from "../../../assets/images/sponsor/sponsor-1.svg";
import BrandLogo10 from "../../../assets/images/sponsor/sponsor-10.svg";
import BrandLogo11 from "../../../assets/images/sponsor/sponsor-11.svg";
import BrandLogo12 from "../../../assets/images/sponsor/sponsor-12.svg";
import BrandLogo3 from "../../../assets/images/sponsor/sponsor-3.svg";
import BrandLogo4 from "../../../assets/images/sponsor/sponsor-4.svg";
import BrandLogo5 from "../../../assets/images/sponsor/sponsor-5.svg";
import BrandLogo6 from "../../../assets/images/sponsor/sponsor-6.svg";
import BrandLogo7 from "../../../assets/images/sponsor/sponsor-7.svg";
import BrandLogo8 from "../../../assets/images/sponsor/sponsor-8.svg";
import BrandLogo9 from "../../../assets/images/sponsor/sponsor-9.svg";
import { fetchPartners } from "../../../services/partners";

class BreandLogo extends Component {
  constructor() {
    super();
    this.state = {
      sponsors: [],
    };
  }
  componentDidMount() {
    this.getSponsors();
  }
  async getSponsors() {
    try {
      const response = await fetchPartners();
      if (response.status === 200 || response.status === 201) {
        const sponsors = response.data?.data;
        this.setState({ sponsors });
      }
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    const { sponsors } = this.state;
    return (
      <>
        {/* ===============  sponsor area start  =============== */}

        {/* ===============  sponsor area start  =============== */}
        <div className="el-sponsor-area" id="el-sponsor-area">
          <div className="container pt-110 position-relative">
            <div className="row">
              <div className="col-lg-12">
                <div className="background-title text-style-one">
                  <h2>Partners & Clients</h2>
                </div>
                <div className="section-head">
                  {/* <h5>Partners & Clients</h5> */}
                  <h3>Our Partners & Clients</h3>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-lg-12">
                <div className="sponsors-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Gold <span>10</span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Silver<span>10</span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        Diamond <span>10</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-lg-12">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="swiper-container">
                      <div className="swiper-wrapper sponsor-slider">
                        <div className="swiper-slide row justify-content-evenly">
                          {sponsors.map((sponsor, index) => (
                            <div
                              key={`sponsor-${sponsor.id}-${index}`}
                              className="col-lg-2 col-md-3 col-sm-4 col-6"
                            >
                              <div className="el-sponsor-logo">
                                <img
                                  src={sponsor.photo || BrandLogo1}
                                  alt={sponsor.name}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  sponsor area end  =============== */}

        {/* ===============  sponsor area end  =============== */}
      </>
    );
  }
}

export default BreandLogo;
