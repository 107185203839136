import $ from "jquery";
/**
 *
 * @param {string} URL
 * @param { object } query
 * @returns
 */
export const queryConverter = (URL, query) => {
  if (query) {
    URL += "?";
    Object.keys(query).forEach((key, idx) => {
      if (["undefined"].includes(typeof query[key])) return;
      URL += `${key}=${query[key].toString()}`;
      if (idx !== Object.keys(query).length - 1) {
        URL += "&";
      }
    });
  }
  return URL;
};

export const initScripts = () => {
  $(document).ready(function () {
    // header active class
    $(".main-nav ul li a").click(function () {
      $("li a").removeClass("active");
      $(this).addClass("active");
    });
    $(".main-nav ul li.has-child-menu ul.sub-menu li a").click(function () {
      $(this)
        .parent()
        .parent()
        .parent()
        .find("a.has-child-menu-item")
        .addClass("active");
    });
    // header active class end

    /****** custom cursor Js ******/
    var cursor = document.querySelector(".cursor");
    var cursorinner = document.querySelector(".cursor2");
    var a = document.querySelectorAll("a");

    document.addEventListener("mousemove", function (e) {
      // var x = e.clientX;
      //  var y = e.clientY;
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
    });

    document.addEventListener("mousemove", function (e) {
      var x = e.clientX;
      var y = e.clientY;
      cursorinner.style.left = x + "px";
      cursorinner.style.top = y + "px";
    });

    document.addEventListener("mousedown", function () {
      cursor.classList.add("click");
      cursorinner.classList.add("cursorinnerhover");
    });

    document.addEventListener("mouseup", function () {
      cursor.classList.remove("click");
      cursorinner.classList.remove("cursorinnerhover");
    });

    a.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("hover");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("hover");
      });
    });

    /***** Mobile Menu Js *****/
    $(".hamburger").on("click", function (event) {
      $(this).addClass("h-active");
      $(".main-nav").addClass("slidenav");
      $(".nav-overlay").addClass("show");
    });

    $(".nav-overlay").on("click", function (event) {
      $(".hamburger").removeClass("h-active");
      $(".main-nav").removeClass("slidenav");
      $(".nav-overlay").removeClass("show");
    });

    $(".header-home .main-nav ul li  a").on("click", function (event) {
      $(".hamburger").removeClass("h-active");
      $(".main-nav").removeClass("slidenav");
    });

    $(".main-nav .fl").on("click", function (event) {
      var $fl = $(this);
      $(this).parent().siblings().find(".sub-menu").slideUp();
      $(this)
        .parent()
        .siblings()
        .find(".fl")
        .addClass("flaticon-plus")
        .text("+");
      if ($fl.hasClass("flaticon-plus")) {
        $fl.removeClass("flaticon-plus").addClass("flaticon-minus").text("-");
      } else {
        $fl.removeClass("flaticon-minus").addClass("flaticon-plus").text("+");
      }
      $fl.next(".sub-menu").slideToggle();
    });

    /****** Sticky Navber Js ******/
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 20) {
        $(".header-area").addClass("sticky");
      } else {
        $(".header-area").removeClass("sticky");
      }
    });
    // preloader
    $(".preloader").delay(1000).fadeOut("slow");

    /****** Custom Select Input JS ******/

    var x, i, j, l, ll, selElmnt, b, c;
    x = document.getElementsByClassName("custom-select");
    l = x.length;
    for (i = 0; i < l; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      ll = selElmnt.length;
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < ll; j++) {
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function (e) {
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML === this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }
    function closeAllSelect(elmnt) {
      var x,
        y,
        i,
        xl,
        yl,
        arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt === y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }
    document.addEventListener("click", closeAllSelect);

    /****** schedule-sidebar JS ******/
    document.querySelectorAll(".sidebar-style-two i").forEach((element) => {
      element.addEventListener("click", () => {
        document
          .querySelectorAll(".schedule-sidebar")
          .forEach((element) => element.classList.add("sb-active"));
      });
    });
    document.querySelectorAll(".sb-toggle-icon").forEach((element) => {
      element.addEventListener("click", () => {
        document
          .querySelectorAll(".schedule-sidebar")
          .forEach((element) => element.classList.remove("sb-active"));
      });
    });
  });
};

export const renderErrorZod = (error, field) => {
  if (error) {
    const _error = error.find((e) => e.path.includes(field));
    return _error ? (
      <div className="text-danger small mt-1 ms-1">{_error.message}</div>
    ) : null;
  }
  return null;
};
