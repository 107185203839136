import { z } from "zod";
import Modal from "./Modal";
import { useState } from "react";
import { isJSON } from "validator";
import { updateMemberPassword } from "../../../services/member";
import { toast } from "react-toastify";
import { renderErrorZod } from "../../../utils";

const schema = z
  .object({
    // current_password: z.string().nonempty(),
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" }),
    confirm_new_password: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirm_new_password) {
      ctx.addIssue({
        path: ["confirm_new_password"],
        message: "Password does not match",
      });
    }
    return null;
  });
const ModalChangePassword = ({ isOpen = false, onClose }) => {
  const [error, setError] = useState(null);
  const [alertError, setAlertError] = useState(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    setAlertError(null);
    try {
      schema.parse(data);
      const response = await updateMemberPassword({ password: data.password });
      if (response.status === 200 || response.status === 201) {
        toast.success("Password updated successfully");
        onClose();
      }
    } catch (error) {
      console.log(error.message);
      isJSON(error.message)
        ? setError(JSON.parse(error.message))
        : setAlertError(error.response.data.message);
    }
  };
  const removeError = (field) => {
    if (error) {
      const _error = error.filter((e) => !e.path.includes(field));
      setError(_error);
    }
  };
  const togglePassword = (key) => {
    setShowPassword((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  return (
    <Modal title="Change Password" isOpen={isOpen} onClose={onClose}>
      <form className="edit-profile-container" onSubmit={handleSubmit}>
        {alertError && (
          <div className="alert alert-danger" role="alert">
            {alertError}
          </div>
        )}
        <div className="d-flex flex-column gap-4">
          {/* <div>
            <label htmlFor="current-password" className="fw-bold small">
              Current Password
            </label>
            <input
              name="current_password"
              type="password"
              placeholder="CURRENT PASSWORD"
              onChange={() => removeError("current_password")}
            />
            {renderErrorZod(error, "current_password")}
          </div> */}
          <div>
            <label htmlFor="password" className="fw-bold small">
              New Password
            </label>
            <div className="d-flex gap-2">
              <input
                name="password"
                type={showPassword.password ? "text" : "password"}
                placeholder="NEW PASSWORD"
                onChange={() => removeError("password")}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => togglePassword("password")}
              >
                {!showPassword.password ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </button>
            </div>
            {renderErrorZod(error, "password")}
          </div>
          <div>
            <label htmlFor="confirm_new_password" className="fw-bold small">
              Confirm New Password
            </label>
            <div className="d-flex gap-2">
              <input
                name="confirm_new_password"
                type={showPassword.confirm ? "text" : "password"}
                placeholder="CONFIRM NEW PASSWORD"
                onChange={() => removeError("confirm_new_password")}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => togglePassword("confirm")}
              >
                {!showPassword.confirm ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </button>
            </div>
            {renderErrorZod(error, "confirm_new_password")}
          </div>
          <div className="mt-2">
            <button className="primary-btn-fill w-100" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ModalChangePassword;
