import axios from "axios";
import api from "../utils/api";

export const fetchContents = async (type) => {
  const URL = `${api.contents}${type ? `/${type}` : ""}`;
  return axios.get(URL);
};

export const fetchContactContent = async () => {
  const URL = api.contactContents;
  return axios.post(URL);
};

export const fetchAboutContents = async (type) => {
  const URL = `${api.aboutContents}/${type}`;
  return axios.post(URL);
};
