import React from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// image import
import EventThumb1 from "../../../assets/images/banners/head-banner.jpg";
import {
  fetchEventCollectionsById,
  fetchEventDetails,
} from "../../../services/events";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// const formatPrice = (tickets, currency = "HKD") => {
//   const prices = tickets?.map((ticket) => ticket.price);
//   if (!prices || prices.length === 0) {
//     return "No Ticket Available";
//   }
//   const minPrice = Math.min(...prices);
//   const maxPrice = Math.max(...prices);
//   return minPrice === maxPrice
//     ? `${currency} $${minPrice}`
//     : `${currency} $${minPrice} - ${currency} $${maxPrice}`;
// };

const handleImageError = (e) => {
  e.target.onerror = null;
  e.target.src = EventThumb1;
};

const Events = () => {
  const params = useParams();
  const [events, setEvents] = React.useState([]);
  const [eventCollection, setEventCollection] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    new WOW.WOW().init();
    getEventCollection();
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getEventCollection = async () => {
    try {
      setLoading(true);
      const id = params.id;
      const response = await fetchEventCollectionsById(id);
      const data = response.data.data;
      setEventCollection(data);

      const eventsPromises = await Promise.allSettled(
        data.events.map(async (collection) => {
          const response = await fetchEventDetails(collection.event_id);
          return response.data.data;
        })
      );
      const events = eventsPromises
        .filter((event) => event.status === "fulfilled")
        .map((event) => event.value);
      setEvents(events);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderNextEvent = (timeslots) => {
    const nearlyEvent = timeslots.filter((time) =>
      moment(time.start_date).isAfter(moment())
    )[0];
    const latestEvent = timeslots[0];
    if (nearlyEvent) return nearlyEvent.start_date;
    return latestEvent.start_date;
  };

  const wowDelay = (index) => 200 * Math.ceil((index + 1) / 2);

  return (
    <>
      {/* ===============  breadcrumb area start =============== */}
      <div
        className="breadcrumb-area"
        style={
          eventCollection?.image
            ? {
                backgroundImage: `-webkit-gradient(linear, left top, left bottom, color-stop(100%, rgba(68, 130, 204, 0.5)), to(rgba(68, 130, 204, 0.5))), url(${eventCollection?.image})`,
              }
            : {}
        }
      >
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="breadcrumb-content">
                <div className="page-outlined-text">
                  <h1>{eventCollection.name || "Event Collection"}</h1>
                </div>
                <h2 className="page-title">Event Collection</h2>
                <ul className="page-switcher">
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Home <i className="bi bi-caret-right" />
                    </Link>
                  </li>
                  <li>Event Collection</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===============  breadcrumb area end =============== */}
      {/* ===============  Event Area start  =============== */}
      <div className="event-area">
        <div className="container position-relative pt-110">
          <div className="row">
            <div className="col-lg-12">
              <div className="background-title text-style-one">
                <h2>{eventCollection.name}</h2>
              </div>
              <div className="section-head">
                <h3>{eventCollection.name || "Event Collection"}</h3>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center my-5 py-5">
              <div
                className="spinner-border"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="row">
              {events?.length > 0 ? (
                <div className="row">
                  {events?.map((event, index) => (
                    <div
                      className="col-xl-6 col-lg-12 col-md-6 wow fadeInUp animated"
                      data-wow-delay={`${wowDelay(index)}ms`}
                      data-wow-duration="1500ms"
                      key={"event-card-" + event.id}
                    >
                      <div className="event-card-md">
                        <div className="event-thumb">
                          <img
                            src={event.image || EventThumb1}
                            alt="Imgs"
                            onError={handleImageError}
                          />
                          <div className="event-lavel" title={event.type}>
                            <i className="bi bi-diagram-3" />
                            <span>{event.type}</span>
                          </div>
                        </div>
                        <div className="event-content">
                          <div className="event-info">
                            <div className="event-date">
                              <div>
                                <i className="bi bi-clock"></i>{" "}
                                {event.timeslots?.length > 0 ? (
                                  moment(
                                    renderNextEvent(event.timeslots)
                                  ).isAfter(moment()) ? (
                                    moment(
                                      renderNextEvent(event.timeslots)
                                    ).format("MMM DD, YYYY, hh:mm a")
                                  ) : (
                                    <span className="text-danger ms-2 fw-medium">
                                      Event Ended
                                    </span>
                                  )
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                            <div className="event-location">
                              <div>
                                <i className="bi bi-geo-alt" />{" "}
                                {event.offline_address ||
                                  event.online_address ||
                                  "-"}
                              </div>
                            </div>
                          </div>
                          <h5 className="event-title">
                            <Link
                              onClick={scrollTop}
                              to={`${process.env.PUBLIC_URL}/event-details/${event.id}`}
                            >
                              {event.name}
                            </Link>
                          </h5>
                          <div className="event-bottom">
                            <div className="event-readme">
                              <Link
                                to={`${process.env.PUBLIC_URL}/event-details/${event.id}`}
                                onClick={scrollTop}
                                className="primary-btn-fill btn-sm btn-sm"
                              >
                                Get Ticket
                              </Link>
                            </div>
                            {/* <h5 className="event-price-tag">
                              {formatPrice(event.tickets)}
                            </h5> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <div className="custom-pagination text-center">
                  <ul className="page-list">
                    <li className="has-arrow">
                      <Link onClick={scrollTop} to={"#"}>
                        <i className="bi bi-chevron-left" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={"#"}
                        className="active-page"
                      >
                        1
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        2
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        3
                      </Link>
                    </li>
                    <li>
                      <Link onClick={scrollTop} to={"#"}>
                        4
                      </Link>
                    </li>
                    <li className="has-arrow">
                      <Link onClick={scrollTop} to={"#"}>
                        <i className="bi bi-chevron-right" />
                      </Link>
                    </li>
                  </ul>
                </div> */}
                </div>
              ) : (
                <div className="mt-5 pt-5 d-flex justify-content-center">
                  <h4 className="fs-1 text-secondary text-uppercase fw-bold">
                    No events found
                  </h4>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* ===============  Event Area end  =============== */}
    </>
  );
};

export default Events;
