import React, { Component } from "react";
import AchiveMentCounter from "./AchiveMentCounter";
import BlogArea from "./BlogArea";
import HeroArea from "./HeroArea";
import HowItWorksArea from "./HowItWorksArea";
import CollectionArea from "./CollectionArea";
import UpcomingEventArea from "./UpcomingEventArea";

class HomePage extends Component {
  render() {
    return (
      <div className="homepage">
        {/* <!-- Start Preloader Area --> */}

        <div className="preloader">
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        {/* <!-- End Preloader Area --> */}
        {/* all section component import start */}
        <HeroArea />
        <UpcomingEventArea />
        <CollectionArea />
        {/* <RecentSchedule /> */}
        <HowItWorksArea />
        {/* <AchiveMentCounter /> */}
        {/* <SpeakerArea /> */}
        {/* <TestimonialArea /> */}
        {/* <EventTimer /> */}
        {/* <BlogArea /> */}
        {/* all section component import start */}
      </div>
    );
  }
}

export default HomePage;
