import React, { Component } from "react";
import OrgabizerIMG from "../../../../assets/images/event/event-orgainizer.png";
// recent event images import
import { fetchPromotionBanners } from "../../../../services/banners";
import PromotionBannerArea from "../PromotionBannerArea";
import { fetchEventOrganizerById } from "../../../../services/events";
class EventRightArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      eventOrganizer: {},
    };
  }
  componentDidMount() {
    this.getPromotionBanners();
    this.getEventOrganizer();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data) {
      this.getEventOrganizer();
    }
  }
  async getEventOrganizer() {
    const { event_organizer_id } = this.props.data;
    try {
      const response = await fetchEventOrganizerById(event_organizer_id);
      this.setState({ eventOrganizer: response.data.data });
    } catch (error) {
      console.log(error);
    }
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  async getPromotionBanners() {
    try {
      const response = await fetchPromotionBanners();
      const activeBanners = response.data.data.filter(
        (banner) => banner.status === 1
      );
      this.setState({ banners: activeBanners });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { data: eventDetails } = this.props;
    const { banners, eventOrganizer } = this.state;
    return (
      <>
        <div className="col-xl-4">
          <div className="event-d-sidebar">
            {/* <div className="event-book-form">
              <div className="category-title">
                <i className="bi bi-bookmark-check" /> <h4>Book This Event</h4>
              </div>
              <form action="#" id="event-book" className="event-book">
                <div className="primary-input-group">
                  <input type="text" id="e-name" placeholder="Your Full Name" />
                </div>
                <div className="primary-input-group">
                  <input type="email" id="e-email" placeholder="Your Email" />
                </div>
                <div className="primary-input-group">
                  <input type="tel" id="e-tel" placeholder="Phone" />
                </div>
                <div className="primary-input-group">
                  <select defaultValue="" className="primary-select">
                    <option>Select quantity</option>
                    <option value={0}>Quantity 1</option>
                    <option value={1}>Quantity 2</option>
                    <option value={2}>Quantity 3</option>
                  </select>
                </div>
                <div className="primary-input-group">
                  <input type="text" id="lname" placeholder="Your Full Name" />
                </div>
                <div className="submit-btn">
                  <button
                    type="submit"
                    className="primary-submit d-block w-100"
                  >
                    Submit Now
                  </button>
                </div>
              </form>
            </div> */}
            <div className="event-d-sidebar-cards">
              <div className="category-title">
                <i className="bi bi-cash-stack"></i> <h4>Refund Policy</h4>
              </div>
              <div className="mx-4 mt-4 fs-6">
                <p>{eventDetails.refund_policy}</p>
              </div>
            </div>
            <div className="event-d-sidebar-cards">
              <div className="category-title">
                <i className="bi bi-geo-alt"></i> <h4>Location</h4>
              </div>
              <div className="mx-4 mt-4 fs-6">
                {eventDetails.offline_address ||
                  eventDetails.online_address ||
                  "-"}
              </div>
            </div>
            <div className="event-organizer-wrap">
              <div className="category-title">
                <i className="bi bi-person-plus" /> <h4>Event Organized By</h4>
              </div>
              <div className="organizer-info">
                <div className="organizer-image">
                  <img
                    src={eventOrganizer.logo || OrgabizerIMG}
                    alt="Imgs"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = OrgabizerIMG;
                    }}
                  />
                </div>
                <h4>{eventOrganizer?.name || ""}</h4>
              </div>
            </div>
            <PromotionBannerArea data={banners} />
          </div>
        </div>
      </>
    );
  }
}

export default EventRightArea;
