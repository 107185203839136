import axios from "axios";
import api from "../utils/api";

export const fetchWishlist = async () => {
    const URL = api.wishlist;
    const token = localStorage.getItem("token");
    return axios.get(URL, { headers: { Authorization: `Bearer ${token}` } });
};

export const fetchMemberWishlist = async () => {
    const URL = `${api.wishlist}/member/get`;
    const token = localStorage.getItem("token");
    return axios.get(URL, { headers: { Authorization: `Bearer ${token}` } });
};

export const addEventToWishlist = async (eventId) => {
    const URL = `${api.wishlist}/member/add`;
    const token = localStorage.getItem("token");
    return axios.post(URL, { event_id: eventId }, { headers: { Authorization: `Bearer ${token}` } });
}

export const removeEventFromWishlist = async (eventId) => {
    const URL = `${api.wishlist}/member/remove`;
    const token = localStorage.getItem("token");
    return axios.delete(URL, { headers: { Authorization: `Bearer ${token}` }, data: { event_id: eventId } });
}