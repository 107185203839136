import React, { Component } from "react";
import { Link } from "react-router-dom";
// page animation
import WOW from "wowjs";
import "../../../assets/css/animate.css";
// image import
import EventIMFG1 from "../../../assets/images/banners/head-banner.jpg";
import { fetchUpcomingEvents } from "../../../services/events";
import moment from "moment";
class UpcomingEventArea extends Component {
  constructor() {
    super();
    this.state = {
      upcomingEvents: [],
    };
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.getEventList();
  }
  async getEventList() {
    try {
      const query = { limit: 6 };
      const response = await fetchUpcomingEvents(query);
      // const response = await fetchEvents({ status: 1, is_published: 1 });
      this.setState({ upcomingEvents: response.data.data });
    } catch (error) {
      console.error(error);
    }
  }
  renderNextEvent(timeslots) {
    const sortedEventByTime = timeslots.sort((a, b) =>
      moment(a.start_date).diff(moment(b.start_date), "hours")
    );
    const pastEvent = sortedEventByTime[0];
    const upcomingEvent = sortedEventByTime[sortedEventByTime.length - 1];
    if (upcomingEvent) return upcomingEvent.start_date;
    return pastEvent.start_date;
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // formatPrice(tickets, currency = "HKD") {
  //   const prices = tickets?.map((ticket) => ticket.price) || [];
  //   if (prices.length === 0) {
  //     return "No Ticket Available";
  //   }
  //   const minPrice = Math.min(...prices);
  //   const maxPrice = Math.max(...prices);
  //   return minPrice === maxPrice
  //     ? `${currency} $${minPrice}`
  //     : `${currency} $${minPrice} - ${currency} $${maxPrice}`;
  // }
  handleImageError(e) {
    e.target.onerror = null;
    e.target.src = EventIMFG1;
  }
  render() {
    const { upcomingEvents } = this.state;
    const wowDelay = (index) => 200 * Math.ceil((index + 1) / 3);
    return (
      <>
        {/* ===============  Event Area start  =============== */}
        <div className="event-area">
          <div className="container position-relative pt-110">
            <div className="row">
              <div className="col-lg-12">
                <div className="background-title text-style-one">
                  <h2>Event</h2>
                </div>
                <div className="section-head">
                  <h3>Upcoming Events / Activities</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="all-event-cards">
                  <div>
                    <div>
                      <div className="row">
                        {upcomingEvents.length > 0 ? (
                          upcomingEvents.map(
                            (event, index) =>
                              index < 6 && (
                                <div
                                  className="col-xl-4 col-md-6 wow fadeInUp animated"
                                  data-wow-delay={`${wowDelay(index)}ms`}
                                  data-wow-duration="1500ms"
                                  key={event.id}
                                >
                                  <div className="event-card-md">
                                    <div className="event-thumb">
                                      <img
                                        src={event.image || EventIMFG1}
                                        alt="Imgs"
                                        onError={this.handleImageError}
                                      />
                                      <div
                                        className="event-lavel"
                                        title={event.hashtags}
                                      >
                                        <i className="bi bi-diagram-3" />
                                        <span>{event.type}</span>
                                      </div>
                                    </div>
                                    <div className="event-content">
                                      <div className="event-info">
                                        <div className="event-date">
                                          <div>
                                            <i className="bi bi-clock"></i>{" "}
                                            {event.timeslots?.length > 0 ? (
                                              moment(
                                                this.renderNextEvent(
                                                  event.timeslots
                                                )
                                              ).isAfter(moment()) ? (
                                                moment(
                                                  this.renderNextEvent(
                                                    event.timeslots
                                                  )
                                                ).format(
                                                  "MMM DD, YYYY, hh:mm a"
                                                )
                                              ) : (
                                                <span className="text-danger ms-2 fw-medium">
                                                  Event Ended
                                                </span>
                                              )
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                        </div>
                                        <div className="event-location">
                                          <div>
                                            <i className="bi bi-geo-alt" />{" "}
                                            {event.offline_address ||
                                              event.online_address ||
                                              "-"}
                                          </div>
                                        </div>
                                      </div>
                                      <h5 className="event-title">
                                        <Link
                                          onClick={this.scrollTop}
                                          to={`${process.env.PUBLIC_URL}/event-details/${event.id}`}
                                        >
                                          {event.name}
                                        </Link>
                                      </h5>
                                      <div className="event-bottom">
                                        <div className="event-readme">
                                          <Link
                                            to={`${process.env.PUBLIC_URL}/event-details/${event.id}`}
                                            // onClick={scrollTop}
                                            className="primary-btn-fill btn-sm btn-sm"
                                          >
                                            Get Ticket
                                          </Link>
                                        </div>
                                        {/* <h5 className="event-price-tag">
                                          {this.formatPrice(event.tickets)}
                                        </h5> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )
                        ) : (
                          <div className="col-12">
                            <div className="fs-1 my-5 text-secondary fw-medium text-center">
                              No upcoming events
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="view-all-wrap wow fadeInLeft animated mt-4"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="view-all-btn pt-0">
                {" "}
                <Link
                  to={`${process.env.PUBLIC_URL}/event`}
                  onClick={this.scrollTop}
                >
                  View All Event
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* ===============  Event Area end  =============== */}
      </>
    );
  }
}

export default UpcomingEventArea;
