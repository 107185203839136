import { useEffect, useMemo, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { createEventReview, fetchEventReviewById } from "../../services/events";
import { isAuthenticated } from "../../utils/auth-check";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
const EventReview = ({ eventId, transactionId, onSubmit, ...props }) => {
  const isLoggedIn = isAuthenticated();
  const [rating, setRating] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleRating = (rate) => {
    setRating(rate);
  };

  const getReview = async () => {
    try {
      if (eventId === undefined) return;
      const response = await fetchEventReviewById({ event_id: eventId });
      if (response.status === 200 || response.status === 201) {
        setReviewData(response.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (rating === 0) return toast.error("Please rate the event");
      const user = JSON.parse(localStorage.getItem("user"));
      const form = e.target;
      const formData = new FormData(form);
      const review = {
        rating,
        description: formData.get("description"),
        event_id: eventId,
        member_id: user.id,
        transaction_id: transactionId,
      };
      await createEventReview(review);
      toast.success("Review submitted successfully");
      onSubmit();
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Failed to submit review");
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    const currentUrl = props.location.pathname;
    props.history.push(`${currentUrl}?login=1`);
  };

  useEffect(() => {
    getReview();
  }, [eventId]);

  const reviewedByTransaction = useMemo(
    () => reviewData.find((item) => item.transaction_id === transactionId),
    [eventId, transactionId, reviewData]
  );

  if (!isLoggedIn) {
    return (
      <div>
        <h5 className="ed-subtitle">Rate Our Event</h5>
        <p className="text-center">Please login to submit a review</p>
        <div className="w-100 d-flex justify-content-center">
          <button className="btn primary-btn-fill mt-3" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h6 className="fs-6 mt-3">Rate Event</h6>
      <form id="review-form" className="mt-3" onSubmit={handleSubmit}>
        <div className="w-100 text-center">
          <Rating
            initialValue={reviewedByTransaction?.rating}
            readonly={!!reviewedByTransaction}
            onClick={handleRating}
          />
        </div>
        {reviewedByTransaction ? (
          <div className="card mt-3">
            <div className="card-body">
              <em>{reviewedByTransaction?.description}</em>
            </div>
          </div>
        ) : (
          <>
            <div className="mt-3">
              <textarea
                name="description"
                placeholder="Write a review..."
                rows={4}
                style={{
                  resize: "none",
                }}
              />
            </div>
            <div className="w-100 d-flex justify-content-end">
              <button
                type="submit"
                disabled={loading}
                className="btn primary-btn-fill mt-3 w-100"
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Submit Review"
                )}
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default withRouter(EventReview);
