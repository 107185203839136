import { z } from "zod";
import Modal from "./Modal";
import { useState } from "react";
import validator, { isJSON } from "validator";
import { registerNewMember } from "../../../services/auth";
import { renderErrorZod } from "../../../utils";
const schema = z
  .object({
    name: z.string().nonempty(),
    // birth_date: z
    //   .string({
    //     message: "Please enter a valid date",
    //   })
    //   .nonempty({
    //     message: "Please enter your birthdate",
    //   }),
    phone: z.string().nonempty().refine(validator.isMobilePhone),
    email: z.string().email(),
    password: z.string().min(8),
    confirm_password: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirm_password) {
      ctx.addIssue({
        path: ["confirm_password"],
        message: "Password does not match",
      });
    }
    return null;
  });
const ModalRegister = ({ isOpen = false, onClose, events }) => {
  const [error, setError] = useState(null);
  const [alertError, setAlertError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    setAlertError(null);
    try {
      schema.parse(data);
      delete data.confirm_password;
      const response = await registerNewMember(data);
      if (response.status === 200) {
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      isJSON(error.message)
        ? setError(JSON.parse(error.message))
        : setAlertError(error.response.data.message);
      setLoading(false);
    }
  };
  const removeError = (field) => {
    if (error) {
      const _error = error.filter((e) => !e.path.includes(field));
      setError(_error);
    }
  };
  const togglePassword = (key) => {
    setShowPassword((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  return (
    <Modal title="Register" isOpen={isOpen} onClose={onClose}>
      {/* alert error */}
      {alertError && (
        <div className="alert alert-danger" role="alert">
          {alertError}
        </div>
      )}
      {!success ? (
        <form
          className="d-flex flex-column gap-4"
          method="post"
          onSubmit={handleSubmit}
        >
          {/* <input name='first_name' type="text" placeholder="FIRST NAME" />
        <input name='first_name' type="text" placeholder="LAST NAME" /> */}
          <div>
            <input
              name="name"
              type="text"
              placeholder="NAME"
              onChange={() => removeError("name")}
            />
            {renderErrorZod(error, "name")}
          </div>
          {/* <div>
            <input
              name="birth_date"
              type="date"
              placeholder="BIRTHDATE"
              onChange={() => removeError("birth_date")}
            />
            {renderErrorZod(error, "birth_date")}
          </div> */}
          <div>
            <input
              name="phone"
              type="number"
              placeholder="PHONE NUMBER"
              onChange={() => removeError("phone")}
            />
            <small className="text-muted small ms-1">e.g. 85280123456</small>
            {renderErrorZod(error, "phone")}
          </div>
          <div>
            <input
              name="email"
              type="email"
              placeholder="EMAIL"
              onChange={() => removeError("email")}
            />
            {renderErrorZod(error, "email")}
          </div>
          <div>
            <div className="d-flex gap-2">
              <input
                name="password"
                type={showPassword.password ? "text" : "password"}
                placeholder="PASSWORD"
                onChange={() => {
                  removeError("password");
                  removeError("confirm_password");
                }}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => togglePassword("password")}
              >
                {!showPassword.password ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </button>
            </div>
            {renderErrorZod(error, "password")}
          </div>
          <div>
            <div className="d-flex gap-2">
              <input
                name="confirm_password"
                type={showPassword.confirm ? "text" : "password"}
                placeholder="CONFIRM PASSWORD"
                onChange={() => removeError("confirm_password")}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => togglePassword("confirm")}
              >
                {!showPassword.confirm ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </button>
            </div>
            {renderErrorZod(error, "confirm_password")}
          </div>
          <div className="mt-2">
            <button
              className="primary-btn-fill w-100"
              type="submit"
              disabled={loading}
            >
              Register{" "}
              {loading && (
                <div
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          <div className="d-flex align-items-center gap-1">
            <span className="fw-medium small">Already a member?</span>
            <div
              role="button"
              className="text-primary fw-medium small ms-1"
              onClick={events.onLoginClick}
            >
              Login
            </div>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <h5>Registration Successful!</h5>
          <p>Thank you for registering with us.</p>
          <button className="primary-btn-fill mt-4" onClick={onClose}>
            Close
          </button>
        </div>
      )}
    </Modal>
  );
};

export default ModalRegister;
