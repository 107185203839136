import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
// import toast from "react-hot-toast";

const CheckoutStripe = ({ children, onSuccess, onFailed }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const paymentElement = elements.getElement(PaymentElement);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      toast.error(
        result.error.message || "Failed to process payment, please try again"
      );
      onFailed(result);
    } else {
      onSuccess(result);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {children}
    </form>
  );
};

export default CheckoutStripe;
