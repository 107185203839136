import { useAtom } from "jotai";
import Modal from "./Modal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { cartAtom } from "../../../store/Cart";
import { deleteFromCart } from "../../../services/carts";
import moment from "moment";
import { toast } from "react-toastify";
const ModalCart = ({ isOpen = false, onClose }) => {
  const [cart, setCart] = useAtom(cartAtom);
  const handleRemoveEventFromCart = async (itemId) => {
    try {
      const response = await deleteFromCart(itemId);
      if (response.status === 200) {
        setCart((prev) => {
          return prev.filter((item) => item.id !== itemId);
        });
        toast.success("Event removed from cart");
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error.response.data.message || "Failed to remove event from cart"
      );
    }
  };
  const getPrice = (item) => {
    let price = 0;
    const ticket = item.event_ticket;
    if (ticket) {
      price = ticket.price * item.qty;
    }
    return price;
  };
  const getEventTime = (item) => {
    let time = "";
    const timeslot = item.event_timeslot;
    if (timeslot) {
      time = timeslot.start_date;
    }
    return time;
  };
  return (
    <Modal title="Cart" isOpen={isOpen} onClose={onClose}>
      <div className="cart-container font-barlow">
        {cart?.length ? (
          <div className="d-flex flex-column gap-3">
            {cart?.map((item, index) => (
              <div key={index} className="w-100 card">
                <Link
                  className="card-header py-3 event-cart-header"
                  to={`/event-details/${item.event_id}?ticket_id=${item.event_ticket_id}&timeslot_id=${item.event_timeslot_id}&qty=${item.qty}`}
                  onClick={onClose}
                >
                  <h5 className="fw-medium">{item.event?.name}</h5>
                </Link>
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                    <div>
                      <h6 className="fw-bold text-secondary mb-2">
                        {item.event_ticket?.name}
                      </h6>
                      <div className="d-flex gap-2 align-items-center">
                        <h6 className="fw-bold" style={{ color: "#702482" }}>
                          HKD ${getPrice(item)}
                        </h6>
                        <p className="text-muted">Qty: {item.qty}</p>
                      </div>
                      <h6 className="fw-medium text-secondary">
                        {moment(getEventTime(item)).format("DD MMM YYYY")}
                      </h6>
                    </div>
                    <button
                      className="btn primary-btn-fill btn-sm"
                      onClick={() => handleRemoveEventFromCart(item.id)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">Your Cart is empty</div>
        )}
      </div>
      <div className="w-100 mt-5">
        <button
          className="btn btn-sm primary-btn-outline w-100"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ModalCart;
