import React, { useEffect, useMemo, useState } from "react";
import EventDetailsLeftArea from "./EventDetailsLeftArea";
import EventRightArea from "./EventRightArea";
import moment from "moment";
import EventEnd from "../../../../assets/images/event/event-end.png";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  addEventToWishlist,
  fetchMemberWishlist,
  fetchWishlist,
  removeEventFromWishlist,
} from "../../../../services/wishlist";
import { useAtom } from "jotai";
import { wishlistAtom } from "../../../../store/Wishlist";
import { isAuthenticated } from "../../../../utils/auth-check";
import ModalChooseTicket from "../../../common/Modals/ModalChooseTicket";
import ModalBookingOverview from "../../../common/Modals/ModalBookingOverview";
import ModalSelectTimeslot from "../../../common/Modals/ModalSelectTimeslot";

const EventDetailsWrapper = (props) => {
  const isLoggedIn = isAuthenticated();
  const [wishlist, setWishlist] = useAtom(wishlistAtom);
  const [openModalSelectEventTime, setOpenModalSelectEventTime] =
    useState(false);
  const [openModalChoseTicket, setOpenModalChoseTicket] = useState(false);
  const [openModalBookingOverview, setOpenModalBookingOverview] =
    useState(false);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const { data: eventDetails } = props;

  useEffect(() => {
    if (!isLoggedIn) return checkLoggedIn();
    if (props.history.location.search) {
      const query = new URLSearchParams(props.history.location.search);
      const ticketId = query.get("ticket_id");
      const timeslotId = query.get("timeslot_id");
      setSelectedTicket(ticketId);
      setSelectedTimeslot(timeslotId);
      setOpenModalChoseTicket(false);
    }
  }, [props.history.location.search]);

  const bookingTicketData = useMemo(() => {
    if (!eventDetails) return [];
    const ticket = eventDetails.tickets?.find(
      (ticket) => ticket.id.toString() === selectedTicket
    );
    const timeslot = eventDetails.timeslots?.find(
      (timeslot) => timeslot.id.toString() === selectedTimeslot
    );
    if (timeslot && ticket) {
      setOpenModalChoseTicket(false);
      setOpenModalBookingOverview(true);
    }
    return {
      ticket,
      timeslot,
      event: eventDetails,
    };
  }, [eventDetails, selectedTicket, selectedTimeslot]);

  const addTowishlist = async () => {
    try {
      const response = await addEventToWishlist(props.data.id);
      if (response.status === 201) {
        await getWishlist();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeWishlist = async () => {
    try {
      const response = await removeEventFromWishlist(props.data.id);
      if (response.status === 200) {
        await getWishlist();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWishlist = async () => {
    try {
      const response = await fetchMemberWishlist();
      if (response.status === 200) {
        setWishlist(response.data.data);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setWishlist([]);
      }
      console.error(error);
    }
  };
  const timeEvent = () => {
    const { data: eventDetails } = props;
    if (!eventDetails.timeslots?.length) return {};
    const sortedStartTimeslots = eventDetails.timeslots?.sort((a, b) => {
      return (
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      );
    });
    const sortedEndTimeslots = eventDetails.timeslots?.sort((a, b) => {
      return new Date(a.end_date).getTime() - new Date(b.end_date).getTime();
    });
    const nearest = sortedStartTimeslots.find(
      (timeslot) =>
        new Date(timeslot.start_date).getTime() >= new Date().getTime()
    );
    return {
      start: sortedStartTimeslots[0].start_date,
      end: sortedEndTimeslots[sortedEndTimeslots.length - 1].end_date,
      nearest: nearest?.start_date,
    };
  };
  const checkLoggedIn = () => {
    if (!isLoggedIn) {
      const currentPath = props.location.pathname;
      const currentSearch = props.location.search;
      const searchParams = new URLSearchParams(currentSearch);
      searchParams.set("login", 1);
      props.history.push(`${currentPath}?${searchParams.toString()}`);
      // props.history.push();
    }
  };

  const {
    start: eventStart,
    end: eventEnd,
    nearest: nearestEvent,
  } = eventDetails && timeEvent();
  const isEventAvailable = new Date(eventEnd).getTime() >= new Date().getTime();
  const isAddedToWishlist = wishlist?.find(
    (event) => event.event_id === eventDetails.id
  );

  const isOneDayEvent =
    moment(eventStart).format("YYYY-MM-DD") ===
    moment(eventEnd).format("YYYY-MM-DD");
  const eventRange = isOneDayEvent
    ? `${moment(eventStart).format("ddd, MMMM DD, YYYY")}, ${moment(
        eventStart
      ).format("hh:mm A")} - ${moment(eventEnd).format("hh:mm A")}`
    : `${moment(eventStart).format("ddd, MMMM DD, YYYY")} - ${moment(
        eventEnd
      ).format("ddd, MMMM DD, YYYY")}`;

  return (
    <>
      <div className="event-details-wrapper">
        <div className="gray-300">
          <div className="container">
            <div className="d-xl-flex align-items-center gap-5">
              <div className="event-detail-header d-flex col-xl-8 gap-5 align-items-center py-5 position-relative">
                {isEventAvailable ? (
                  <div className="event-date-container text-center">
                    <h5>{moment(nearestEvent).format("MMMM")}</h5>
                    <h2 className="event-date">
                      {moment(nearestEvent).format("DD")}
                    </h2>
                    <h5>{moment(nearestEvent).format("YYYY")}</h5>
                  </div>
                ) : eventDetails.timeslots?.length > 0 ? (
                  <div className="event-date-container text-center my-auto">
                    <img src={EventEnd} alt="event-end" width={48} />
                    <h5 className="mt-3">Event Ended</h5>
                  </div>
                ) : null}
                <div>
                  <h3 className="event-title mb-3">{eventDetails.name}</h3>
                  <div className="event-time">
                    <i className="bi bi-clock"></i>{" "}
                    {eventDetails.timeslots?.length > 0 ? (
                      <span>{eventRange}</span>
                    ) : (
                      <span>No Event Scheduled</span>
                    )}
                  </div>
                  <div className="event-location">
                    <i className="bi bi-geo-alt"></i>{" "}
                    {eventDetails.offline_address ||
                      eventDetails.online_address ||
                      "-"}
                  </div>
                </div>
              </div>
              {isEventAvailable && (
                <div className="col-xl">
                  <div>
                    <button
                      className="btn primary-btn-fill w-100 btn-lg"
                      onClick={() =>
                        !isLoggedIn
                          ? checkLoggedIn()
                          : setOpenModalSelectEventTime(true)
                      }
                    >
                      Book Now
                    </button>
                  </div>
                  <div className="mt-3">
                    <button
                      className="btn primary-btn-outline w-100 btn-lg"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => {
                        !isLoggedIn
                          ? checkLoggedIn()
                          : !isAddedToWishlist
                          ? addTowishlist()
                          : removeWishlist();
                      }}
                    >
                      {isAddedToWishlist ? (
                        <>
                          <i className="bi bi-heart-fill me-2"></i>
                          Remove from Wishlist
                        </>
                      ) : (
                        <>
                          <i className="bi bi-heart me-2"></i>
                          Add to Wishlist
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container position-relative">
          {/* <div className="background-title text-style-one">
              <h2>Event Details</h2>
            </div> */}
          <div className="row">
            {/* event detils child componet */}
            <EventDetailsLeftArea data={eventDetails} />
            <EventRightArea data={eventDetails} />
          </div>
        </div>
      </div>
      <ModalChooseTicket
        isOpen={openModalChoseTicket}
        ticketData={eventDetails.tickets}
        onClose={() => setOpenModalChoseTicket(false)}
        onBack={() => {
          setOpenModalChoseTicket(false);
          setOpenModalSelectEventTime(true);
        }}
        onSelectTicket={(ticket) => {
          const query = new URLSearchParams({
            ticket_id: ticket.id,
            timeslot_id: selectedTimeslot,
          }).toString();
          setOpenModalChoseTicket(false);
          setOpenModalBookingOverview(true);
          props.history.push(`/event-details/${eventDetails.id}?${query}`);
        }}
      />
      <ModalSelectTimeslot
        isOpen={openModalSelectEventTime}
        title={eventDetails.name}
        data={eventDetails.timeslots}
        onClose={() => setOpenModalSelectEventTime(false)}
        onSelect={(selectedTime) => {
          setSelectedTimeslot(selectedTime.id);
          setOpenModalSelectEventTime(false);
          setOpenModalChoseTicket(true);
        }}
      />
      <ModalBookingOverview
        isOpen={openModalBookingOverview}
        data={bookingTicketData}
        onClose={() => {
          setOpenModalBookingOverview(false);
          setSelectedTicket(null);
          setSelectedTimeslot(null);
          props.history.push(`/event-details/${eventDetails.id}`);
        }}
      />
    </>
  );
};

export default withRouter(EventDetailsWrapper);
