import Modal from "./Modal";
import EventReview from "../EventReview";
import { useEffect, useMemo, useState } from "react";
import { getMemberTransaction } from "../../../services/transactions";
import TitleIcon from "../../../assets/images/icons/title.svg";
import CreditCard from "../../../assets/images/payments/credit-card.png";
import { fetchEventDetails } from "../../../services/events";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PaymentStatus = {
  PENDING: {
    label: "Booking Pending",
    badgeClass: "badge-warning",
  },
  PAID: {
    label: "Booking Success",
    badgeClass: "badge-success",
  },
  DONE: {
    label: "Booking Success",
    badgeClass: "badge-success",
  },
  FAILED: {
    label: "Booking Failed",
    badgeClass: "badge-danger",
  },
  REFUNDED: {
    label: "PAYMENT REFUNDED",
    badgeClass: "badge-warning",
  },
};
const ModalMyTransaction = ({ isOpen = false, onClose }) => {
  const [transactions, setTransactions] = useState([]);
  const [transactionDetail, setTransactionDetail] = useState();
  const [eventDetails, setEventDetails] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setTransactionDetail(undefined);
      getMyTransaction();
    }
  }, [isOpen]);

  const ticketType = useMemo(
    () =>
      eventDetails?.tickets?.find(
        (ticket) =>
          ticket.id === transactionDetail?.events?.[0]?.event_ticket_id
      ).name,
    [eventDetails]
  );

  const timeslot = useMemo(
    () =>
      eventDetails?.timeslots?.find(
        (ts) => ts.id === transactionDetail?.events?.[0]?.event_timeslot_id
      ),
    [eventDetails]
  );

  const getEventDetails = async (id) => {
    try {
      const response = await fetchEventDetails(id);
      setEventDetails(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMyTransaction = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const response = await getMemberTransaction(user.id);
      if (response.status === 200) {
        setTransactions(response.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleViewDetails = async (transaction) => {
    setLoading(true);
    await Promise.all([
      setTransactionDetail(transaction),
      getEventDetails(transaction?.events?.[0]?.event_id),
    ]);
    setLoading(false);
  };

  const handleSubmitReview = () => {
    setTransactionDetail(undefined);
    onClose();
  };

  const platformFee = useMemo(() => {
    const paymentDetail = transactionDetail?.payment_detail;
    const detail = paymentDetail ? JSON.parse(paymentDetail) : {};
    return detail?.object?.application_fee_amount || 0;
  }, [transactionDetail]);

  return (
    <Modal
      title={
        transactionDetail ? (
          <div className="d-flex gap-2 align-items-center">
            <div>
              <i
                role="button"
                class="bi bi-chevron-left fs-5"
                onClick={() => setTransactionDetail(undefined)}
              ></i>
            </div>
            <div>Transaction Details</div>
          </div>
        ) : (
          "My Transactions"
        )
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="cart-container font-barlow">
        {loading ? (
          <div className="w-100 d-flex justify-content-center mt-3">
            <div
              className="spinner-border ms-2"
              style={{ width: "3rem", height: "3rem" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {transactions?.length ? (
              <>
                {!transactionDetail ? (
                  <>
                    {
                      <div className="d-flex flex-column gap-3">
                        {transactions.map((transaction) => (
                          <div
                            key={`trx-${transaction.id}`}
                            className="w-100 card"
                          >
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div>
                                  Booking ID:{" "}
                                  <strong>{transaction?.order_number}</strong>
                                </div>
                                <strong>
                                  {transaction?.currency || "HKD"} $
                                  {transaction?.total.toFixed(2)}
                                </strong>
                              </div>
                              {/* <div className="d-flex gap-2 align-items-center mt-2">
                            <img src={TitleIcon} alt="title" />
                            <p>Event Title</p>
                          </div> */}
                              <div className="d-flex justify-content-between align-items-center mt-2 text-bg">
                                <div>
                                  <div
                                    className={`badge ${
                                      PaymentStatus[transaction?.status]
                                        ?.badgeClass
                                    }`}
                                  >
                                    {PaymentStatus[transaction?.status]?.label}
                                  </div>
                                </div>
                                <button
                                  className="btn primary-btn-outline btn btn-sm"
                                  onClick={() => handleViewDetails(transaction)}
                                >
                                  View Details
                                </button>
                              </div>
                              {/* {transaction.payment_status === "PENDING" && (
                            <Link
                              to={`${process.env.PUBLIC_URL}/event-details/${transaction.events?.[0].event_id}?ticket_id=${transaction.events?.[0].event_ticket_id}&timeslot_id=${transaction.events?.[0].event_timeslot_id}&transaction_id=${transaction.id}`}
                              onClick={onClose}
                            >
                              <button className="btn primary-btn-fill btn-sm mt-2 w-100 mt-3">
                                Pay Now
                              </button>
                            </Link>
                          )} */}
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                  </>
                ) : (
                  <>
                    {loading ? (
                      <div className="w-100 d-flex justify-content-center mt-3">
                        <div
                          className="spinner-border ms-2"
                          style={{ width: "3rem", height: "3rem" }}
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      eventDetails && (
                        <div className="w-100 card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <div>
                                Booking ID:{" "}
                                <strong>
                                  {transactionDetail?.order_number}
                                </strong>
                              </div>
                              <strong>
                                {transactionDetail?.currency || "HKD"} $
                                {transactionDetail?.total.toFixed(2)}
                              </strong>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 gap-2">
                              <div className="d-flex align-items-center">
                                <img src={TitleIcon} alt="title" />
                                <p>{eventDetails.name}</p>
                              </div>
                              <div
                                className={`badge ${
                                  PaymentStatus[transactionDetail?.status]
                                    ?.badgeClass
                                }`}
                              >
                                {
                                  PaymentStatus[transactionDetail?.status]
                                    ?.label
                                }
                              </div>
                            </div>
                            <div className="d-flex flex-column gap-1 mt-4">
                              <div>
                                <div className="text-secondary">
                                  Ticket Type
                                </div>
                                <p>{ticketType}</p>
                              </div>
                              <div>
                                <div className="text-secondary">Date</div>
                                <p>
                                  {moment(timeslot?.start_date).isValid()
                                    ? moment(timeslot?.start_date).format(
                                        "DD MMMM YYYY"
                                      )
                                    : ""}
                                </p>
                              </div>
                              <div>
                                <div className="text-secondary">Time</div>
                                <p>
                                  {moment(timeslot?.start_date).isValid()
                                    ? moment(timeslot?.start_date).format(
                                        "HH:mm"
                                      )
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <div className="mt-3">
                              <div className="fw-bold">Payment Information</div>
                              <div className="d-flex justify-content-between text-secondary">
                                <div>
                                  Standard Ticket x{" "}
                                  {transactionDetail?.events?.[0]?.qty}
                                </div>
                                <div>
                                  {transactionDetail?.currency || "HKD"} $
                                  {transactionDetail?.subtotal_event.toFixed(2)}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between text-secondary">
                                <div>Platform Fee</div>
                                <div>
                                  {transactionDetail?.currency || "HKD"} $
                                  {platformFee?.toFixed(2)}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between text-secondary">
                                <div>Discount</div>
                                <div className="text-danger">
                                  {transactionDetail?.currency || "HKD"} -$
                                  {transactionDetail.total_discount.toFixed(2)}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div>
                              <div className="d-flex justify-content-between fw-medium">
                                <div>Total</div>
                                <div>
                                  {transactionDetail?.currency || "HKD"} $
                                  {transactionDetail?.total.toFixed(2)}
                                </div>
                              </div>
                            </div>

                            {transactionDetail.payment !== null && (
                              <>
                                <div className="fw-bold my-2">Paid With</div>
                                <div className="d-flex justify-content-between fw-medium">
                                  <div>Credit Card</div>
                                  <div>
                                    <img src={CreditCard} alt="credit card" />
                                  </div>
                                </div>
                              </>
                            )}

                            {transactionDetail.payment_status === "PAID" && (
                              <>
                                <hr />
                                <EventReview
                                  eventId={
                                    transactionDetail.events?.[0]?.event_id
                                  }
                                  transactionId={transactionDetail?.id}
                                  onSubmit={handleSubmitReview}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="text-center">You have no transactions</div>
            )}
          </>
        )}
      </div>
      <div className="w-100 mt-5">
        <button
          className="btn btn-sm primary-btn-outline w-100"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ModalMyTransaction;
